import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import countriesList from "../country.json";
import Loader from "../Loader";
import ConfirmationModal from "../Setting/ConfirmationModal";
import {
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { validateCoupon } from "../../services/paymentService";

import {
  register,
  getMembershipLevel,
  subscribefree,
  createMemberPayment,
  createMemberCoursePayment,
  subadminInfo,
  getSaleSelectedCourse,
  getMemberData,
  login,
  getSaleSelectedEvent,
  createMemberEventPayment,
  startPurchaseCourse,
  startPurchaseEvent,
  joinEvent,
  enrollCourse,
} from "../../helper/service";
import { commonUtil } from "../../helper/CommonUtils";

const TaxRate = 0.1;

export default function CheckoutRegistration(props) {
  const {
    subscriptionId,
    subscriptionFrequency,
    subadminId,
    courseId,
    courseFrequency,
    eventId,
    eventFrequency,
  } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState({});
  const [couponError, setCouponError] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    country: "Australia",
  });
  const [formErrors, setFormErrors] = useState({});
  const [memberData, setMemberData] = useState({});
  const [brandColor, setBrandColor] = useState("");
  const [plan, setPlan] = useState({});
  const [isMonthly, setIsMonthly] = useState(true);
  const [orderPrice, setOrderPrice] = useState(0.0);
  const [originalOrderPrice, setOriginalOrderPrice] = useState(0.0);
  const [orderTax, setOrderTax] = useState(0.0);
  const [orderDiscount, setOrderDiscount] = useState(0.0);
  const [orderTotal, setOrderTotal] = useState(0.0);
  const [hasError, setHasError] = useState(false);
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [disablePassword, setDisablePassword] = useState(false);
  const [message, setMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    var isMonthlyPayment = props.subscriptionFrequency === "monthly";
    setIsMonthly(isMonthlyPayment);

    const data = await subadminInfo(subadminId);
    if (data) {
      setBrandColor(data.result.validUser.brandColor);
      localStorage.setItem(
        "subadminBrandColor",
        data.result.validUser.brandColor
      );
    }

    let subsId = subscriptionId;
    if (subsId) {
      getMembershipLevel(subsId).then((subs) => {
        if (subs?.result?.validSubs) {
          var subscription = subs?.result?.validSubs;
          if (subscription) {
            setPlan(subscription);

            if (subscription.substype === "Free") {
              props.updateHeadingHandler(
                "YOU ARE NOW SUBSCRIBING TO A FREE PLAN"
              );
            }

            var orderMonthlyPrice =
              Math.round(subscription.amountMonthly * 100) / 100;
            var orderYearlyPrice =
              Math.round(subscription.amountYearly * 100) / 100;

            var originalPrice = isMonthlyPayment
              ? orderMonthlyPrice
              : orderYearlyPrice;
            var currentPrice = originalPrice / 1.1;
            var tax = currentPrice * TaxRate;

            setOrderPrice(currentPrice.toFixed(2));
            setOriginalOrderPrice(originalPrice);
            setOrderTax(tax.toFixed(2));
            setOrderTotal(originalPrice.toFixed(2));
          }
        }
      });
    } else if (courseId) {
      getSaleSelectedCourse(courseId).then((course) => {
        let selectedCourse = course.result[0];
        if (selectedCourse.amount == 0) {
          props.updateHeadingHandler(
            "YOU ARE NOW PAYING TO PURCHASE A FREE COURSE"
          );
        }
        let singleCourse = {
          tier: selectedCourse.title,
          description: selectedCourse.shortDescription,
          amount: selectedCourse.amount,
        };
        var orderPrice = Math.round(selectedCourse.amount * 100) / 100;
        var currentPrice = orderPrice / 1.1;
        var tax = currentPrice * TaxRate;
        setPlan(singleCourse);
        setOrderPrice(currentPrice.toFixed(2));
        setOriginalOrderPrice(orderPrice);
        setOrderTax(tax.toFixed(2));
        setOrderTotal(orderPrice.toFixed(2));
      });
    } else if (eventId) {
      getSaleSelectedEvent(eventId).then((event) => {
        let selectedEvent = event.result;
        let singleEvent = {
          tier: selectedEvent.name,
          description: selectedEvent.description.replace(/<[^>]*>/g, ""),
          amount: selectedEvent.eventAmount,
        };
        if (selectedEvent.eventAmount == 0) {
          props.updateHeadingHandler(
            "YOU ARE NOW PAYING TO PURCHASE A FREE EVENT"
          );
        }
        var orderPrice = Math.round(selectedEvent.eventAmount * 100) / 100;
        var currentPrice = orderPrice / 1.1;
        var tax = currentPrice * TaxRate;
        setPlan(singleEvent);
        setOrderPrice(currentPrice.toFixed(2));
        setOriginalOrderPrice(orderPrice);
        setOrderTax(tax.toFixed(2));
        setOrderTotal(orderPrice.toFixed(2));
        setIsLoading(false);
      });
    }
  };

  const {
    firstName,
    lastName,
    email,
    password,
    phone,
    postalCode,
    streetAddress,
    country,
  } = formData;

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email)) {
      getMemberData(email).then((data) => {
        let user = data.result.validUser;
        if (user && user.subadminsId) {
          setMemberData(user);
          if (subscriptionId) {
            let partIndex = user.subadminsId.findIndex(
              (subadmin) => subadmin.subadminId._id === subadminId
            );
            let checkSubs = user.subscriptionId[partIndex]?.subsId?._id;
            let checkSubsStatus = user.subscriptionId[partIndex]?.status;
            if (subscriptionId === checkSubs && checkSubsStatus === "Active") {
              setWarningModal(true);
              setMessage(
                "You already have this subscription. Go back and buy other subscription."
              );
              setModalType("warning");
              setWarningModalTitle("Warning!");
              setShowAuthenticate(false);
              setDisableNextBtn(true);
            } else {
              setWarningModal(true);
              setMessage(
                "Looks like you are already a member with Wisdome, please enter password to continue."
              );
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(true);
              setDisableNextBtn(true);
            }
          }
          else if (courseId) {
            const hasPurchasedCourse = user.userCourses.some(
              (course) => course.purchaseCourseId === courseId
            );

            if (hasPurchasedCourse) {
              setWarningModal(true);
              setMessage("You have already purchased this course.");
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(false);
              setDisableNextBtn(true);
            }
            else if (user.subadminsId.length > 0) {
              setWarningModal(true);
              setMessage(
                "Looks like you are already a member with Wisdome, please enter password to continue."
              );
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(true);
              setDisableNextBtn(true);
            }
          }
          else if (eventId) {
            const hasPurchasedEvent = user.userEvents.some(
              (event) => event.purchaseEventId === eventId
            );

            if (hasPurchasedEvent) {
              setWarningModal(true);
              setMessage("You have already purchased this event.");
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(false);
              setDisableNextBtn(true);
            }
            else if (user.subadminsId.length > 0) {
              setWarningModal(true);
              setMessage(
                "Looks like you are already a member with Wisdome, please enter password to continue."
              );
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(true);
              setDisableNextBtn(true);
            }
          }
          else {
            setWarningModal(true);
            setMessage(
              "Looks like you are already a member with Wisdome, please enter password to continue."
            );
            setModalType("info");
            setWarningModalTitle("FYI");
            setShowAuthenticate(true);
            setDisableNextBtn(true);
          }
        }
      });
    }
  }, [email]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCoupon = () => {
    let type = "";
    let modId = "";
    if (props.subscriptionId) {
      type = "subscriptions"
      modId = props.subscriptionId
    }
    else if (courseId) {
      type = "courses"
      modId = courseId
    }
    else if (eventId) {
      type = "events"
      modId = eventId
    }
    validateCoupon(coupon, type, modId,"public")
      .then((data) => {
        setCoupon("");
        setCouponError("");
        if (data) {
          setCouponData(data.result);
          applyCoupon(data.result);
        }
      })
      .catch((err) => {
        console.log("coupon error: " + err);
        setCouponError("Invalid coupon code");
      });
  };

  const applyCoupon = (couponData) => {
    if (couponData) {
      if (couponData.discountType == "fixed") {
        if (couponData.discount < originalOrderPrice) {
          setOrderDiscount(couponData.discount);
          var currentPrice = (originalOrderPrice - couponData.discount) / 1.1;
          var tax = currentPrice * TaxRate;
          setOrderPrice(currentPrice.toFixed(2));
          setOrderTax(tax.toFixed(2));
          setOrderTotal((currentPrice + tax).toFixed(2));
        }
        else {
          setOrderDiscount(couponData.discount);
          setOrderPrice(0);
          setOrderTax(0);
          setOrderTotal(0);
        }
      }
      else {
        var discountRate = couponData.discount / 100;
        var discountAmount = originalOrderPrice * discountRate;
        setOrderDiscount(discountAmount.toFixed(2));
        var currentPrice = (originalOrderPrice - discountAmount) / 1.1;
        var tax = currentPrice * TaxRate;
        setOrderPrice(currentPrice.toFixed(2));
        setOrderTax(tax.toFixed(2));
        setOrderTotal((currentPrice + tax).toFixed(2));
      }
    } else {
      setCouponError("Invalid coupon code");
    }
  };

  const removeCoupon = (e) => {
    e.preventDefault();
    setCoupon("");
    setCouponError("");
    setCouponData({});
    var currentPrice = originalOrderPrice / 1.1;
    setOrderPrice(currentPrice.toFixed(2));
    setOrderDiscount(0.0);
    var tax = currentPrice * TaxRate;
    setOrderTax(tax.toFixed(2));
    setOrderTotal((currentPrice + tax).toFixed(2));
  };

  const validate = () => {
    let errors = {};
    let validRes = true;

    if (!firstName) {
      errors.firstName = "*Required";
      validRes = false;
    } else {
      errors.firstName = "";
    }

    if (!lastName) {
      errors.lastName = "*Required";
      validRes = false;
    } else {
      errors.lastName = "";
    }

    if (!password) {
      errors.password = "*Required";
      validRes = false;
    }
    else if (password?.length < 6) {
      errors.password = "Password should be minimum 6 characters long";
      validRes = false;
    }
    else {
      errors.password = "";
    }


    if (!email) {
      errors.email = "*Required";
      validRes = false;
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        errors.email = "*Required";
        validRes = false;
      }
      else {
        errors.email = "";
      }
    }
    if (!country) {
      //errors.country = '*Required';
    }
    if (!streetAddress) {
      errors.streetAddress = "*Required";
      validRes = false;
    }
    else {
      errors.streetAddress = "";
    }
    if (!postalCode) {
      errors.postalCode = "*Required";
      validRes = false;
    } else {
      errors.postalCode = "";
    }
    if (!phone) {
      errors.phone = "*Required";
      validRes = false;
    } else {
      errors.phone = "";
    }

    setFormErrors(errors);
    return validRes;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      password,
      phone,
      country,
      streetAddress,
      postalCode,
    } = formData;

    const dataIsValid = validate();
    let registrationRequest = {
      firstName,
      lastName,
      email,
      password,
      mobile: phone,
      subadmin: subadminId,
      couponData: couponData,
    };
    if (dataIsValid) {
      localStorage.setItem("subadminId", subadminId);
      if (subscriptionId) {
        registrationRequest.subsId = subscriptionId
        registrationRequest.subsPlan = subscriptionFrequency

        setIsLoading(true);
        if (!isAuthenticated) {
          register(registrationRequest).then((memberData) => {
            if (memberData.status === 200) {
              subscriptionPayment(registrationRequest);
            }
          });
        } else {
          subscriptionPayment(registrationRequest);
        }
        setIsLoading(false);
      } else if (courseId) {
        registrationRequest.courseId = courseId
        registrationRequest.coursePlan = "onetime"

        setIsLoading(true);
        if (!isAuthenticated) {
          register(registrationRequest).then((memberData) => {
            if (memberData.status === 200) {
              coursePayment(registrationRequest);
            }
          });
        } else {
          coursePayment(registrationRequest);
        }
      } else if (eventId) {
        registrationRequest.eventId = eventId
        registrationRequest.eventPlan = "onetime"
        setIsLoading(true);
        if (!isAuthenticated) {
          register(registrationRequest).then((memberData) => {
            if (memberData.status === 200) {
              eventPayment(registrationRequest);
            }
          });
        } else {
          eventPayment(registrationRequest);
        }
      }
      setIsLoading(false);
    }
  };

  const subscriptionPayment = async (registrationRequest) => {
    if (plan.substype !== "Free") {
      let coupon = couponData?.couponCode || 0;

      if ((couponData?.discount < 100 && couponData.discountType == "percentage") || (couponData?.discount < originalOrderPrice && couponData.discountType == "fixed") || Object.keys(couponData).length === 0) {

        createMemberPayment(subscriptionId, subscriptionFrequency, coupon)
          .then((data) => {
            if (data && data.success) {
              props.startPaymentHandler(data, registrationRequest);
            }
          })
          .catch((err) => {
            console.log("create member payment error: " + err);
            setHasError(true);
            props.errorHandler(
              "Error occured while processing payment. Please try again later or try contacting your instructor."
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      else {
        let sdata = {
          subadminId: subadminId,
          couponCode: couponData?.couponCode,
          subscriptionFrequency: subscriptionFrequency,
          subscriptionId: {
            subsId: subscriptionId,
          },
        };
        subscribefree(sdata).then((data) => {
          setWarningModal(true);
          setMessage("You have successfully purchased subscription");
          setModalType("info");
          setWarningModalTitle("FYI");
        })
      }
    } else {
      let data = {
        subadminId: subadminId,
        subscriptionId: {
          subsId: subscriptionId,
        },
      };
      subscribefree(data).then((data) => {
        setWarningModal(true);
        setMessage("You have selected free membership.");
        setModalType("info");
        setWarningModalTitle("FYI");
      });
    }
  };
  const coursePayment = async (registrationRequest) => {
    let coupon = couponData?.couponCode || 0;
    let data = {
      fullName:
        firstName + " " + lastName,
      email: email?.trim(),
      phone: phone?.trim(),
      country: country,
      streetAddress: streetAddress,
      postalCode: postalCode,
      courseId: courseId,
      couponCode: couponData?.couponCode,
      token: localStorage.getItem("token"),
      subadminId: localStorage.getItem("subadminId"),
    };
    if (plan.amount > 0) {
      if ((couponData?.discount < 100 && couponData.discountType == "percentage") || (couponData?.discount < plan.amount && couponData.discountType == "fixed") || Object.keys(couponData).length === 0) {
        createMemberCoursePayment(courseId, "onetime", coupon)
          .then((data) => {
            if (data && data.success) {
              props.startPaymentHandler(data, registrationRequest);
            }
          })
          .catch((err) => {
            console.log("create member payment error: " + err);
            setHasError(true);
            props.errorHandler(
              "Error occured while processing payment. Please try again later or try contacting your instructor."
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      else if ((couponData?.discount == 100 && couponData.discountType == "percentage") || (couponData?.discount > plan.amount && couponData.discountType == "fixed")) {
        data.discount = 100
        startPurchaseCourse(data).then(async (cou) => {
          let enrollUser = await enrollCourse(courseId)
          setWarningModal(true);
          setMessage("You have successfully purchased course");
          setModalType("info");
          setWarningModalTitle("FYI");
        })
      }
    }
    else {
      data.discount = 100
      startPurchaseCourse(data).then(async (cou) => {
        let enrollUser = await enrollCourse(courseId)
        setWarningModal(true);
        setMessage("You have successfully purchased free course");
        setModalType("info");
        setWarningModalTitle("FYI");
      })
    }
  };
  const eventPayment = async (registrationRequest) => {
    let coupon = couponData?.couponCode || 0;
    let data = {
      fullName:
        firstName + " " + lastName,
      email: email?.trim(),
      phone: phone?.trim(),
      country: country,
      streetAddress: streetAddress,
      postalCode: postalCode,
      eventId: eventId,
      couponCode: couponData?.couponCode,
      token: localStorage.getItem("token"),
      subadminId: localStorage.getItem("subadminId"),
    };
    if (plan.amount > 0) {
      if ((couponData?.discount < 100 && couponData.discountType == "percentage") || (couponData?.discount < plan.amount && couponData.discountType == "fixed") || Object.keys(couponData).length === 0) {
        createMemberEventPayment(eventId, "onetime", coupon)
          .then((data) => {
            if (data && data.success) {
              props.startPaymentHandler(data, registrationRequest);
            }
          })
          .catch((err) => {
            console.log("create member payment error: " + err);
            setHasError(true);
            props.errorHandler(
              "Error occured while processing payment. Please try again later or try contacting your instructor."
            );
          })
          .finally(() => {
            setIsLoading(false);
          });

      }
      else if ((couponData?.discount == 100 && couponData.discountType == "percentage") || (couponData?.discount > plan.amount && couponData.discountType == "fixed")) {
        data.discount = 100
        startPurchaseEvent(data).then(async (eve) => {
          let enrollUser = await joinEvent(eventId);
          setWarningModal(true);
          setMessage("You have successfully purchased event");
          setModalType("info");
          setWarningModalTitle("FYI");
        })
      }
    }
    else {
      data.discount = 100
      startPurchaseEvent(data).then(async (eve) => {
        let enrollUser = await joinEvent(eventId);
        setWarningModal(true);
        setMessage("You have successfully purchased free event");
        setModalType("info");
        setWarningModalTitle("FYI");
      })
    }
  };

  const checkAuthenication = () => {
    let data = {
      email: email,
      password: password,
    };
    login(data).then((res) => {
      if (!res.success) {
        setWarningModal(true);
        setMessage("Invalid credentials.");
        setModalType("warning");
        setWarningModalTitle("ERROR!!!");
      } else {
        setDisableNextBtn(false);
        setIsAuthenticated(true);
        setDisableEmail(true);
        setDisablePassword(true);
        setFormData({
          firstName: res.user.firstName,
          lastName: res.user.lastName,
          phone: res.user.mobile,
          email: res.user.email,
          password: data.password,
        });
      }
    });
  };

  const headingStyle = {
    color: brandColor,
    fontWeight: "bold",
    fonnSize: "1.5rem",
  };

  const inputBoxStyle = {
    backgroundColor: "white",
    borderColor: "#D2D2D2",
    height: "2.5em",
  };

  const priceSectionStyle = {
    fontWeight: "600",
    fonnSize: "1.5rem",
  };
  const infoMessageStyle = {
    fontSize: "0.7rem",
    color: "gray",
  };
  const errorMessageStyle = {
    color: "red",
    fontSize: "0.7rem",
  };
  const lineStyle = {
    width: "55%",
    marginLeft: "5%",
    marginRight: "20%",
    marginTop: ".3em",
    marginBottom: ".3em",
    height: "1px",
    backgroundColor: "#D2D2D2",
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={warningModalTitle}
          message={message}
          freeSubsCheckModel={true}
          onClose={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
            if (subscriptionId) {
              if ((couponData?.discount == 100 &&couponData.discountType=="percentage")  || (couponData?.discount > originalOrderPrice&&couponData.discountType=="fixed")) {
                commonUtil.redirect("/account-details");
              }
              else if (
                modalType === "info" &&
                message === "You have selected free membership."
              ) {
                commonUtil.redirect("/account-details");
              } else if (
                modalType === "warning" &&
                message ===
                "You already have this subscription. Go back and buy other subscription."
              ) {
                window.location.reload();
              }
            }
            else if (courseId) {
              if ((couponData?.discount == 100 &&couponData.discountType=="percentage")  || (couponData?.discount > originalOrderPrice&&couponData.discountType=="fixed")) {
                commonUtil.redirect("/account-details");
              }
              else if (
                modalType === "info" &&
                message === "You have successfully purchased free course"
              ) {
                commonUtil.redirect("/account-details");
              }
            }
            else if (eventId) {
              if ((couponData?.discount == 100 && couponData.discountType == "percentage") || (couponData?.discount > originalOrderPrice && couponData.discountType == "fixed")) {
                commonUtil.redirect("/account-details");
              }
              else if (
                modalType === "info" &&
                message === "You have successfully purchased free event"
              ) {
                commonUtil.redirect("/account-details");
              }
            }
          }}
        />
      )}
      {!hasError && (
        <Form>
          <Row>
            <Col>
              <Form.Label style={headingStyle}>ACCOUNT DETAILS</Form.Label>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                  disabled={disableEmail}
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.email}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="Create your password"
                  required
                  disabled={disablePassword}
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.password}</span>
              </Form.Group>
              {showAuthenticate && (
                <Form.Group>
                  <Button
                    style={{ backgroundColor: brandColor, border: "none" }}
                    onClick={checkAuthenication}
                    type="submit"
                    disabled={isAuthenticated}
                  >
                    Authenticate
                  </Button>
                  {isAuthenticated && (
                    <span style={{ marginLeft: "5px" }}>
                      Authenticated successfully
                    </span>
                  )}
                </Form.Group>
              )}
              <Form.Label style={headingStyle}>
                BILLING and CONTACT DETAILS
              </Form.Label>
              <Form.Group>
                <span style={errorMessageStyle}>*</span>
                <span style={{ fontSize: "0.7rem" }}> required fields</span>
              </Form.Group>

              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.firstName}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.lastName}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  placeholder="Enter your phone"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.phone}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  onChange={handleChange}
                  value={country}
                  required
                  style={inputBoxStyle}
                  className="country-dropdown"
                >
                  {countriesList.map((country, index) => (
                    <option key={country.code + index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
                {/* <span style={errorMessageStyle}>{formErrors.country}</span> */}
              </Form.Group>
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  value={streetAddress}
                  onChange={handleChange}
                  placeholder="Enter street address"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>
                  {formErrors.streetAddress}
                </span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Postal Code / ZIP</Form.Label>
                <Form.Control
                  type="number"
                  name="postalCode"
                  value={postalCode}
                  onChange={handleChange}
                  placeholder="Enter postal code"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.postalCode}</span>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="column2">
                {props.subscriptionId && (
                  <Form.Label style={headingStyle}>PLAN DETAILS</Form.Label>
                )}
                {courseId && (
                  <Form.Label style={headingStyle}>PRODUCT DETAILS</Form.Label>
                )}
                {eventId && (
                  <Form.Label style={headingStyle}>Event DETAILS</Form.Label>
                )}
                <Form.Text>
                  <div className="d-flex align-items-center">
                    <strong>{plan.tier}</strong>
                  </div>
                </Form.Text>
                <Form.Text>
                  <div className="d-flex align-items-center">
                    <span>{plan.description}</span>
                  </div>
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="column3">
                <Form.Label style={headingStyle}>YOUR ORDER</Form.Label>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>
                        {subscriptionId && "Plan"}
                        {courseId && "Course"}
                        {eventId && "Event"}
                      </Form.Text>
                    </Col>

                    <Col>
                      <Form.Text style={priceSectionStyle}>Total</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      {courseId && <Form.Text>Course Price</Form.Text>}
                      {eventId && <Form.Text>Event Price</Form.Text>}
                      {props.subscriptionId && (
                        <Form.Text>
                          Business{" "}
                          {plan.substype !== "Free" && isMonthly
                            ? "(Monthly)"
                            : "(Yearly)"}
                        </Form.Text>
                      )}
                    </Col>
                    <Col>
                      <Form.Text> ${orderPrice}</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>Taxes</Form.Text>
                    </Col>
                    <Col>
                      <Form.Text> ${orderTax}</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>Discount</Form.Text>
                    </Col>
                    <Col>
                      <Form.Text> ${orderDiscount}</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>Total</Form.Text>
                    </Col>
                    <Col>
                      <Form.Text style={priceSectionStyle}>
                        {" "}
                        ${orderTotal}
                      </Form.Text>
                      <Form.Text style={{ "font-style": "italic" }}>
                        Currency in AUD
                      </Form.Text>
                    </Col>
                    {(orderTotal < 0.5 && orderTotal > 0.00) &&
                      <div className="mt-3 d-flex align-items-center bg-light p-3 rounded w-75">
                        <i className="fas fa-info-circle me-2 text-info fs-4"></i>{" "}
                        <span className="fs-9 text-dark">
                          Our system charges 0.5 dollars when payment comes down between 0.01 to 0.5 dollars.
                        </span>
                      </div>
                    }
                  </Row>
                </Form.Group>
              </Form.Group>
              {plan.amount > 0 &&
                <Form.Group controlId="column4">
                  <Form.Label style={headingStyle}>COUPON</Form.Label>
                  <Form.Text>
                    <strong>Have a coupon code?</strong>
                  </Form.Text>
                  <Row className="py-2 b-1">
                    <Col className="col-md-10">
                      <Form.Control
                        type="text"
                        placeholder="Enter your coupon code"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        style={inputBoxStyle}
                      />
                    </Col>
                    <Col className="col-md-2">
                      <Button
                        style={{ backgroundColor: brandColor, border: "none" }}
                        type="button"
                        onClick={handleCoupon}
                        disabled={coupon === ""}
                      >
                        Apply
                      </Button>
                    </Col>
                    <Col className="col-md-12">
                      <span style={infoMessageStyle}>
                        Only one coupon is allowed
                      </span>
                    </Col>
                    <Col className="col-md-12">
                      <span style={errorMessageStyle}>
                        {couponError ? couponError : ""}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    {couponData?.couponCode && (
                      <Col>
                        <span style={{ fontSize: "0.8rem" }}>
                          {couponData.couponCode}
                        </span>
                        <span style={{ fontSize: "0.8rem", marginLeft: '1em' }}>
                          {couponData.displayText.length > 40 ? couponData.displayText?.slice(0, 40) : couponData.displayText}
                        </span>
                        <Button variant="link" onClick={removeCoupon}>
                          <FontAwesomeIcon icon={faXmark} color="red" />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Form.Group>
              }
              <Form.Group>
                <Button
                  style={{
                    backgroundColor: brandColor,
                    border: "none",
                    width: "100px",
                  }}
                  className="mt-3"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={disableNextBtn}
                >
                  {subscriptionId && plan.substype !== "Free" ? "Next" :
                    (courseId || eventId) && plan.amount !== 0 ? "Next" : "Subscribe"}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
