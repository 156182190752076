import React, { useEffect, useState } from "react";
import AllCoursesFoldersList from "./AllCoursesFoldersList";
import searchDark from "../../images/enduser/search-dark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faList } from "@fortawesome/free-solid-svg-icons";
import CourseGrid from "./CourseGrid";

function CoursesSection() {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeView, setActiveView] = useState<string>("list");
  const [brandColor, setBrandColor] = useState<string>("");

  useEffect(() => {
    const color = localStorage.getItem("subadminBrandColor") || "#919294";
    setBrandColor(color);
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <section className="courses-folders">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex items-content-center">
            <h5 className="fs-6">Courses</h5>
            <hr className="flex-fill ml-4" />
          </div>

          <div className="col-md-12 d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center">
              <button
                className="icon-button mr-2"
                aria-label="List View"
                onClick={() => setActiveView("list")}
                style={{
                  color: activeView === "list" ? brandColor : "#919294",
                }}
              >
                <i className="fas fa-list fa-2x"></i>  
              </button>
              <button
                className="mr-2 icon-button"
                aria-label="Grid View"
                onClick={() => setActiveView("grid")}
                style={{
                  color: activeView === "grid" ? brandColor : "#919294",
                }}
              >
                <i className="fas fa-th-large fa-2x"></i>
              </button>

              <div className="search-course position-relative mr-3">
                <input
                  type="text"
                  className="form-control pe-5"
                  placeholder="Search course"
                  name="search"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <span
                  className="search-icon position-absolute"
                >
                  <img
                    src={searchDark}
                    alt="Search"
                    style={{ cursor:"pointer" }}
                  />{" "}
                  
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            {activeView === "list" ? (
              <AllCoursesFoldersList searchQuery={searchQuery} />
            ) : (
              <CourseGrid searchQuery={searchQuery} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoursesSection;