import React, { Component } from "react";
import Header from "../common/Header";
import Exclusion from "../../images/audio/Exclusion 50.svg";
import Group351 from "../../images/audio/Mask Group 351.png";
import { Modal, Button } from "react-bootstrap";
import close from "../../images/dashboard/modal-close.svg";
import edit from "../../images/edit.svg";
import gallery from "../../images/gallery.svg";
import $ from "jquery";

import book2 from "../../images/admin/book2.svg";
import read from "../../images/admin/read.svg";
import ic_error from "../../images/dashboard/ic_error.svg";
import book from "../../images/admin/book.svg";
//import { subscription } from "../../helper/service";
import Group69383 from "../../images/admin/Group 69383.svg";
import Group68030 from "../../images/admin/Group 68030.svg";
import calendars from "../../images/admin/calendar (8).svg";
import arrows from "../../images/admin/arrows.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import slideleft1 from "../../images/slide-left1.svg";
import slideright1 from "../../images/slide-right1.svg";
import visibility from "../../images/admin/visibility (2).svg";
import chat from "../../images/admin/chat (9).svg";
import Group68025 from "../../images/admin/Group 68025.svg";
import Group68026 from "../../images/admin/Group 68026.svg";
import { NavLink } from "react-router-dom";
import folder from "../../images/admin/music-sm.svg";
import file from "../../images/admin/file.svg";
import goback from "../../images/dashboard/go-back.svg";
import dragdrop from "../../images/admin/drag&drop.svg";
import Group68551 from "../../images/admin/Group 68551.svg";
import Group69395 from "../../images/admin/Group 69395.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import success from "../../images/audio/success.svg";
import Loader from "../Loader";
import magnifiyingGlass from "../../images/admin/magnifiying-glass.svg";

import lesson from "../../images/admin/music-sm-o-hover.svg";
import MacAppLabels from "../common/MacAppLabels";
import FolderComponent from "./FolderComponent";
import TopSearchResultComponent from "./TopSearchResultComponent";
import AddCoursesFolderModal from "./AddCoursesFolderModal";
import CreateCourseModal from "./CreateCourseModal";
import ChooseLessonWithCourseModal from "./ChooseLessonWithCourseModal";
import CreateLessonsModal from "./CreateLessonsModal";
import CourseLessonAddedSuccessModal from "./CourseLessonAddedSuccessModal";
import CourseListModal from "./CourseListModal";
import AddFileModal from "./AddFileModal";
import icError from "../../images/dashboard/ic_error.svg";
import fileClose from "../../images/admin/file-close.svg";
import plusIcon from "../../images/admin/plus-icon.svg";
import agenda from "../../images/admin/agenda.svg";
import agendaHover from "../../images/admin/agenda-hover.svg";
import music from "../../images/admin/music.svg";
import musicHover from "../../images/admin/music-hover.svg";

import {
  folderList,
  totalCourses,
  getRecentCourses,
  getTopCourses,
  getScheduledCourses,
  totalLessonCount,
  getLessonsByCourseId,
  subadminAllDataCount
} from "../../helper/service";
import { Link } from "react-router-dom";
export default class CoursesLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCourseModalOpen: false,
      isCreateCourseModalOpen: false,
      isCreateLessonsModalOpen: false,
      isCourseLessonAddedSuccess: false,
      isCourseListModalOpen: false,
      isFileModalOpen: false,
      isOpenChooseLessonWithCourseModal: false,
      folders: [],
      subscriptionData: "",
      selectedId: "",
      selectedFolder: "",
      parentTitle: "",
      parentSubtitle: "",
      parentTotalcontent: "",
      parentTotalsubfolder: "",
      parentFolderImage: "",
      subfoldersdata: "",
      isRedirect: false,
      totalFolder: 0,
      selectedTitle: "",
      date1: new Date(),
      is_loader: false,
      isOpen7: false,
      isOpen8: false,
      isOpen9: false,
      options: "",
      Audios: "",
      startDate: new Date(),
      dates: new Date(),
      courseDates: new Date(),
      dates3: new Date(),
      recentVideo: "",
      scheduledVideo: "",
      currentTab: "current",
      title: "",
      shortDescription: "",
      description: "",
      courseImage: "",
      folderLocation: "",
      isPaid: false,
      paymentLink: "",
      memberLevelAccess: "",
      imgError: "",
      courseId: "",
      parentId: "",
      schedule: "",
      allCourses: [],
      goBackBtn: "",
      courseOptions: [],
      totalLesson: [],
      addedLessonDetails: [],
      totalUser: 0,
      hideCourseOption: false,
      selectedFiles: [],
      newResource: [],
      gallery: gallery,
      subscriptionListData: "",
      isOpen: false,
      isOpen1: false,
      isOpen2: true,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false,
      isPaid: false,
      isOpen6: false,
      selectedFile: [],
      dragdrop: dragdrop,
      accessAllowed: true,
    };
  }

  openModal = () => this.setState({ isOpen: true, isOpen6: false, gallery: gallery });
  closeModal = () => this.setState({ isOpen: false });
  openModal1 = () =>
    this.setState({ isOpen1: true, isOpen: false, isOpen4: false, gallery: gallery });
  closeModal1 = () => this.setState({ isOpen1: false });
  openModal2 = () =>
    this.setState({
      isOpen2: true,
      isOpen: false,
      isOpen6: false,
      isOpen5: false,
      isOpen3: false,
      gallery: gallery,
      isOpen4: false,
    });
  closeModal2 = () => this.setState({ isOpen2: false });
  openModal3 = () =>
    this.setState({ isOpen3: true, isOpen2: false, isOpen1: false, isOpen4: false });
  closeModal3 = () => this.setState({ isOpen3: false });
  openModal4 = () => this.setState({ isOpen4: true });
  closeModal4 = () => this.setState({ isOpen4: false });
  openModal5 = () => this.setState({ isOpen5: true, isOpen4: false });
  closeModal5 = () => this.setState({ isOpen5: false });

  openModal6 = () =>
    this.setState({
      isOpen6: true,
      isOpen5: false,
      isOpen4: false,
      isOpen2: false,
      isOpen3: false,
    });
  closeModal6 = () => this.setState({ isOpen6: false });

  subscriptionlist(subscription) {
    if (subscription) {
      const datas = subscription.map(function (subs) {
        return (
          <div className="access-level">
            <span className="float-left">{subs.tier}</span>
            <div className="custom-control custom-switch float-right">
              <input
                type="checkbox"
                className="custom-control-input"
                id={subs._id}
                value="bronze"
                name="bronze"
              />
              <label className="custom-control-label" htmlFor={subs._id} />
            </div>
          </div>
        );
      });
      this.setState({
        subscriptionData: datas,
      });
    }
  }

  async componentDidMount() {
    subadminAllDataCount().then((data) => {
      if (data?.result?.partnerData) {
        if (data.result.partnerData.foldersLength > data.result.partnerData.currentFoldersAllowed
          || data.result.partnerData.membersLength > data.result.partnerData.currentMembersAllowed
          || data.result.partnerData.memberALLength > data.result.partnerData.currentSubscriptionAllowed
        ) {
          this.setState({ accessAllowed: false });
        }
      }
    });
    // this.getRecentAudioList();
    // this.getscheduledAudioList();
    // this.getTopAudioList();
    const userId = localStorage.getItem("user_id");
    //this.getFolder();
    //this.totalLessonCount('61c88b29c8266ecf5ae65c84');
    this.getRecentCourses();

    // subscription(userId).then(data => {
    //   this.subscriptionlist(data.result);
    //   })
  }

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
    if (name == "courseId") {
      this.setState({ selectedId: value });
    }
  };
  onImageChange = (event) => {
    if (
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpg" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        let img1 = URL.createObjectURL(event.target.files[0]);
        this.setState({
          courseImage: img,
          gallery: img1,
          img1: img1,
        });
      }
    } else {
      alert("Please upload valid image.");
    }
  };
  handleFileChange = (event) => {
    console.log(`Selected file - ${event.target.files[0].name}`);
    let img = URL.createObjectURL(event.target.files[0]);
    this.setState({
      selectedFiles: event.target.files[0].name,
      dragdrop: img,
    });
  };

  handleLessonDateChange = (date) => {
    this.setState({ dates: date });
  };
  handleCourseDateChange = (date) => {
    this.setState({ courseDates: date });
  };

  openCoursesModal = () => {
    this.openModal4();
  };

  openCreateCoursesModal = () => {
    this.openModal1();
  };

  openChooseLessonWithCourseModal = () => {
    this.openModal5();
    this.closeModal3();
  };

  opeCreateLessonsModal = () => {
    this.openModal2();
  };

  openCourseLessonAddedSuccessModal = () => {
    this.setState({
      isCourseLessonAddedSuccess: true,
      isCreateCourseModalOpen: false,
      isCreateLessonsModalOpen: false,
    });
  };

  openCourseListModal = () => {
    this.openModal3();
    this.closeModal5();
  };

  openAddFileModal = () => {
    this.setState({ isFileModalOpen: true, isCreateLessonsModalOpen: false });
  };

  closeModal = (modalName) => {
    if (modalName == "chooseFolder") {
      this.setState({ addCourseModalOpen: false });
    } else if (modalName == "createCourses") {
      this.setState({ isCreateCourseModalOpen: false });
    } else if (modalName == "createLessons") {
      this.setState({ isCreateLessonsModalOpen: false });
    } else if (modalName == "success") {
      this.setState({ isCourseLessonAddedSuccess: false });
    } else if (modalName == "courseList") {
      this.setState({ isCourseListModalOpen: false });
    } else if (modalName == "fileModal") {
      this.setState({ isFileModalOpen: false });
    } else if (modalName == "selectCourse") {
      this.setState({ isOpenChooseLessonWithCourseModal: false });
    }
  };

  goBack = () => {
    if (this.state.goBackBtn == "courseList") {
      this.openChooseLessonWithCourseModal();
      this.closeModal("createLessons");
    } else if (this.state.goBackBtn == "createLesson") {
      this.closeModal("createLessons");
      this.openCoursesModal();
    }
  };

  savedata1 = async (event) => {
    this.setState(
      {
        btn: event.target.name,
      },
      () => {
        this.createCourse("createCourses");
      }
    );
  };

  /*Get All course data on carousel */
  getFolder = async () => {
    const datas = await totalCourses();
    this.setState({ allCourses: datas.result, allRecords: datas.result });
    const courseData = this.createCourseOption(datas.result);
    this.setState({ courseOptions: courseData });
    this.iterateFolders(datas);
    this.iterateFolderOptions(datas);
    this.iterateFolderonPopup(datas);
  };

  subscriptionlist(subscription) {
    var datas = "";
    if (subscription) {
      datas = subscription.map(function (subs) {
        return (
          <div className="access-level">
            <span className="float-left">{subs.tier}</span>
            <div className="custom-control custom-switch float-right">
              <input
                type="checkbox"
                className="custom-control-input"
                id={subs._id}
                value="bronze"
                name="bronze"
              />
              <label className="custom-control-label" htmlFor={subs._id} />
            </div>
          </div>
        );
      });
    }
    this.setState({
      subscriptionData: datas,
    });
  }

  getRecentCourses = async () => {
    const datas = await getRecentCourses();
    this.setState({ allCourses: datas.result });
    const datas1 = {
      result: datas.result,
    };
    console.log(datas1, "course_data");
    this.iterateFolders(datas1);
    const userId = localStorage.getItem("user_id");
    this.setState({ totalFolder: datas.result.length });
  };

  getScheduledCourses = async () => {
    const datas = await getScheduledCourses();
    this.setState({ allCourses: datas.result });
    this.iterateFolders(datas);
    const userId = localStorage.getItem("user_id");
    this.setState({ totalFolder: datas.result.length });
  };

  getTopCourses = async () => {
    const datas = await getTopCourses();
    this.setState({ allCourses: datas.result });
    this.iterateFolders(datas);
    const userId = localStorage.getItem("user_id");
    this.setState({ totalFolder: datas.result.length });
  };

  // async courseList(folderId){
  //     const audios =  await getCoursesByFolderId(folderId);
  //     console.log(folderId)
  //     this.iterateAudios(audios);
  // }
  createCourseOption = (data) => {
    if (data.length > 0) {
      let options = data.map((e) => {
        return (
          <option key={e._id} value={e._id}>
            {e.title}
          </option>
        );
      });
      options.unshift(
        <option key={data.length} value={-1}>
          -Select-
        </option>
      );
      return options;
    } else {
      return [];
    }
  };

  iterateFolderOptions(datas) {
    const options = datas.result.map(function (subs) {
      if (subs.parentId == 0) {
        return <option>{subs.title}</option>;
      }
    }, this);
    this.setState({
      options: options,
    });
  }
  iterateFolders = async (datas) => {
    var i = 0;
    var folders =
      datas.result.length > 0
        ? datas.result.map(function (subs) {
            i++;
            if (i === 1) {
              console.log("aaaaaa");
            }

            if (subs.tLesson > 1) {
              var lesson = "Lessons";
            } else {
              var lesson = "Lesson";
            }

            if (subs.tUser > 1) {
              var Students = "Students";
            } else {
              var Students = "Student";
            }

            return (
              <div className="item" key={subs._id}>
                <div className="main-img">
                  <img src={subs.courseImage} className="img-over" />
                </div>
                <Link to={`/courses-details/${subs._id}`}>
                  <h5 className="slide-title">{subs.title}</h5>
                </Link>
                <p className="slide-text">
                  {subs.tLesson} {lesson}
                </p>
                <p className="slide-text float-left">
                  {subs.tUser} {Students}
                </p>
                <div className="float-right d-flex">
                  {/*<img src="images/admin/elm-general-photo2.png" width="25" height="25">*/}
                  {/*<img src="images/admin/elm-general-photo2.png" width="25" height="25">*/}
                  {/*<img src="images/admin/elm-general-photo2.png" width="25" height="25">*/}
                </div>
                <div className="clearfix"></div>
              </div>
            );
          }, this)
        : [];

    if (folders.length == 0) {
      var folders = "No records found.";
    }
    this.setState({
      folders: folders,
    });
  };

  iterateFolderonPopup = async (datas) => {
    const folderspopup = datas.result.map(function (subs) {
      return (
        <div
          className="card"
          id={subs._id}
          onClick={this.selectMainFolder.bind(this, subs._id, subs)}
        >
          <div className="card-body">
            <div className="row no-gutters">
              <div className="col-4">
                <img src={subs.courseImage} className="img-fluid audio-img" alt="book" />
              </div>
              <div className="col-8 pl-2">
                <h5 className="card-title">{subs.title}</h5>
                <p className="card-text">{subs.shortDescription}</p>
                <div className="row no-gutters align-items-center">
                  <div className="col">
                    <div className="page-info">
                      <img src={folder} />
                      {subs.TotalLesson} Lessons
                    </div>
                  </div>
                  <div className="col">
                    <div className="student-info">
                      {/* <img src={file} /> */}
                      {subs.TotalUser} Students
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }, this);
    this.setState({
      foldersPopup: folderspopup,
    });
  };
  async getLessonsByCourseId(courseId) {
    //const lessons =  await getLessonsByCourseId(courseId);
    // console.log(lessons, 'lessons')
    // this.iterateAudios(audios);
    //return lessons.length;
  }

  async getTotalUserByCourseId(courseId) {
    //const user =  await getTotalUserByCourseId(courseId);
    //this.setState({totalUser: user.length});
  }

  totalLessonCount = async (idd) => {
    // const lessons = await totalLessonCount(idd);
    //let prevOptions = this.createCourseOption(lessons.result);
    //this.setState({prevLessonsOptions : prevOptions, totalLesson:lessons.result,totalLessonCount:lessons.result.length})
  };

  selChildFolder(obj) {
    $(document).ready(function () {
      $(".card").removeClass("active");
      $("#" + obj._id).addClass("active");
    });
  }

  async selectMainFolder(idd, obj) {
    $(document).ready(function () {
      $(".card").removeClass("active");
      $("#" + idd).addClass("active");
    });

    localStorage.setItem("selectedFolder", idd);
    localStorage.setItem("selectedParentFolder", idd);

    this.setState({
      selectedFolder: idd,
      selectedParentFolder: idd,
      parentTitle: obj.title,
      parentDescription: obj.description,
      parentTotalcontent: obj.totalcontent,
      parentTotalsubfolder: obj.totalsubfolder,
      parentFolderImage: obj.courseImage,
    });

    const datas = await totalLessonCount(idd);
    let lessonCount = datas.result.length;
    // let totalUserofOneCourse = await getTotalUserByCourseId(idd);
    // console.log(totalUserofOneCourse);
    // totalUserofOneCourse = totalUserofOneCourse.result.length;
    var avail = false;
    if (datas && datas.result && datas.result.length > 0) {
      var subfoldersdata = datas.result.map(function (subs) {
        return (
          <div className="card" id={subs._id} onClick={this.selChildFolder.bind(this, subs)}>
            <div className="card-body">
              <div className="row no-gutters">
                <div className="col-2">
                  <img src={lesson} className="img-fluid mr-2" alt="book" height="75" width="75" />
                </div>
                <div className="col-10">
                  <h5 className="file-title">{subs.title}</h5>
                  <p className="file-text">{subs.description}</p>
                </div>
              </div>
            </div>
          </div>
        );
      }, this);
    } else {
      var avail = true;
    }
    this.setState({
      subfoldersdata: subfoldersdata || [],
      avail: avail,
    });
  }

  handleNewResourseChange = (event) => {
    event.preventDefault();
    let elm = $(".direct-add-file");
    this.setState({ is_loader: true });
    if (elm[0].files && elm[0].files[0]) {
      let newResources = elm[0].files[0];
      this.setState({ newResource: newResources });
    }
  };
  searchData = (event) => {
    console.log(this.state.allCourses, "allcoursesss");
    event.preventDefault();
    const { name, value } = event.target;
    console.log("value====", value);
    //this.setState({keySearch:value})
    let filteredNames = this.state.allCourses.filter((ele) =>
      ele.title.toLowerCase().includes(value.toLowerCase())
    );
    let datas = { result: filteredNames };
    console.log(datas, " filtereddata");
    this.iterateFolders(datas);
  };

  searchNow = (event) => {
    var value = this.state.keySearch;
    let filteredNames = this.state.allCourses.r1.filter((ele) =>
      ele.title.toLowerCase().includes(value.toLowerCase())
    );
    let datas = { result: filteredNames };
    //console.log("Datas ==== ",datas)
    this.iterateFolders(datas);
  };

  loadLessons = () => {
    getLessonsByCourseId(this.state.selectedParentFolder);
    this.openCourseListModal();
  };

  render() {
    $(document).ready(function () {
      $(".createFolder, .subfolders").attr("id", "wantToCreateModal");
      $(".wantToCreateModal").attr("id", "wantToCreateModal");
      $(".selectSubFolderModal").attr("id", "selectSubFolderModal");
      $(".uploadAudioModal").attr("id", "uploadLessonModal");
      $(".audioAddedModal").attr("id", "audioAddedModal");
      $(".addAudioDetailsModal").attr("id", "addAudioDetailsModal");
      $(".audioPreviewModal").attr("id", "audioPreviewModal");
      $(".insideFolderModal").attr("id", "insideFolderModal");
      $(".createFolderModal").attr("id", "createFolderModal");
      $(".lessonAddedModal").attr("id", "lessonAddedModal");
      $(".addLessonDetailsModal").attr("id", "addLessonDetailsModal");
      $(".selectFolderModal").attr("id", "selectFolderModal");
    });

    return (
      <div className="wrapper">
        <Header />
        {this.state.is_loader ? <Loader /> : <></>}
        <div className="content-wrapper admin-courses">
          <section className="">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="title">
                      {MacAppLabels.courses.heading}
                      {/* <a href="#">{MacAppLabels.subHeading}</a> */}
                    </h2>
                  </div>
                  {/* <div className="col-lg-6">
                                            <button className="btn btn-custom" data-toggle="modal" data-target="#wantToCreateModal" onClick={this.openCoursesModal}>{MacAppLabels.courses.addCourseLabel}</button>
                                        </div> */}
                </div>
              </div>
            </div>
          </section>
          
          {this.state.accessAllowed ? <FolderComponent
            searchNow={this.searchNow}
            searchData={this.searchData}
            folders={this.state.folders}
            getScheduledCourses={this.getScheduledCourses}
            getTopCourses={this.getTopCourses}
            getRecentCourses={this.getRecentCourses}
          />
          : <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger" role="alert">
                  You have reached the limit of your subscription. Please upgrade your subscription to add more courses.
                </div>
              </div>
            </div>
          </div>}
          {/* <TopSearchResultComponent /> */}
        </div>

        {/* <AddCoursesFolderModal isModalOpen ={this.state.addCourseModalOpen} openCreateCoursesModal={this.openCreateCoursesModal} openChooseLessonWithCourseModal={this.openChooseLessonWithCourseModal} closeModal={this.closeModal} />
                    <CreateCourseModal  isCreateCourseModalOpen ={this.state.isCreateCourseModalOpen} gallery={this.state.gallery} closeModal={this.closeModal} createCourse={this.createCourse} onChange={this.onChange} onImageChange={this.onImageChange} savedata1={this.savedata1} handleCourseDateChange={this.handleCourseDateChange} courseDates={this.state.courseDates} subscriptionListData={this.state.subscriptionListData}/>
                    <ChooseLessonWithCourseModal isOpenChooseLessonWithCourseModal={this.state.isOpenChooseLessonWithCourseModal} opeCreateLessonsModal={this.opeCreateLessonsModal} openCourseListModal={this.openCourseListModal} openAddFileModal={this.openAddFileModal} data={this.state.allCourses} getLessonsByCourseId={this.totalLessonCount} closeModal={this.closeModal} foldersPopup={this.state.foldersPopup} selectedParentFolder={this.state.selectedParentFolder} subscriptionListData={this.state.subscriptionListData}/>
                    <CreateLessonsModal handleNewResourseChange={this.handleNewResourseChange} isCreateLessonsModalOpen ={this.state.isCreateLessonsModalOpen} closeModal={this.closeModal}  onChange={this.onChange} uploadLesson={this.uploadLesson}  addLessonDetails={this.addLessonDetails} openCreateCoursesModal={this.openCreateCoursesModal} openAddFileModal={this.openAddFileModal} goBack={this.goBack} courseOptions={this.state.courseOptions} handleLessonDateChange={this.handleLessonDateChange} dates={this.state.dates} prevLessonsOptions={this.state.prevLessonsOptions} hideCourseOption={this.state.hideCourseOption} subscriptionData={this.state.subscriptionData}/>
                    <CourseLessonAddedSuccessModal isCourseLessonAddedSuccess = {this.state.isCourseLessonAddedSuccess} closeModal={this.closeModal} />
                    <CourseListModal isModalOpen ={this.state.isCourseListModalOpen} closeModal={this.closeModal} openChooseLessonWithCourseModal={this.openChooseLessonWithCourseModal} opeCreateLessonsModal={this.opeCreateLessonsModal}  lessonData={this.state.subfoldersdata} parentDescription={this.state.parentDescription} parentTitle = {this.state.parentTitle} parentImage={this.state.parentFolderImage}/>
                    <AddFileModal isFileModalOpen={this.state.isFileModalOpen} opeCreateLessonsModal={this.opeCreateLessonsModal} uploadLesson={this.uploadLesson} selectedTitle={this.state.selectedTitle}  closeModal={this.closeModal} openCoursesModal={this.openCoursesModal} handleFileChange={this.handleFileChange} selectedFile={this.state.selectedFiles}/>
               */}

        <Modal
          className="createFolder"
          show={this.state.isOpen4}
          onHide={this.closeModal4}
          backdrop="static"
        >
          <Modal.Header className="justify-content-end align-items-end border-0 pb-0">
            <h5 className="modal-title" id="wantToCreateModalLabel">
              What do you want to create?
            </h5>
            <button
              type="button"
              className="close ml-5"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModal4}
            >
              <span aria-hidden="true">
                <img src={close} />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="d-flex flex-wrap justify-content-center align-items-center text-center">
              <div className="folder mr-md-3" onClick={this.openCreateCoursesModal}>
                {/* <span><i className="fas fa-folder" /></span> */}
                <div className="folder-img">
                  <img src={agenda} className="agenda" />
                  <img src={agendaHover} className="agenda-hover" />
                </div>
                <p className="title">Courses</p>
              </div>
              <div className="folder ml-md-3" onClick={this.openChooseLessonWithCourseModal}>
                {/* <span><i className="fas fa-microphone" /></span> */}
                <div className="folder-img">
                  <img src={music} className="music" />
                  <img src={musicHover} className="music-hover" />
                </div>
                <p className="title">Lessons</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="insideFolderModal"
          show={this.state.isOpen3}
          onHide={this.closeModal3}
          backdrop="static"
        >
          <Modal.Header class="modal-header align-items-end border-0">
            <div class="go-back" onClick={this.openChooseLessonWithCourseModal}>
              <img src={goback} class="img-fluid mr-3" /> Courses list
            </div>
            <button
              type="button"
              class="close ml-5"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModal3}
            >
              <span aria-hidden="true">
                <img src={close} />
              </span>
            </button>
          </Modal.Header>
          <div class="modal-body px-0">
            <div class="folder">
              <div class="card">
                <div class="card-body">
                  <div class="row no-gutters">
                    <div class="col-4">
                      <img src={this.state.parentFolderImage} class="audio-img img-fluid" />
                    </div>

                    <div class="col-8 pl-2">
                      <h5 class="card-title">{this.state.parentTitle}</h5>
                      <p class="card-text">{this.state.parentDescription}</p>
                      <div class="row d-flex align-items-center">
                        <div class="col-5">
                          <div class="page-info">
                            <img src={folder} />
                            {this.state.lessonData && this.state.lessonData.length} Lessons
                          </div>
                        </div>
                        <div class="col-7">
                          <div class="student-info">
                            {/* 42 Students */}
                            {/* <img src={this.studentImg} width={30} height={30} />
                                                        <img src={this.studentImg} width={30} height={30} />
                                                        <img src={this.studentImg} width={30} height={30} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll">
                {this.state.subfoldersdata ? this.state.subfoldersdata : null}
              </div>
              <div class="d-flex justify-content-center">
                <button class="btn btn-custom" onClick={this.openModal6}>
                  Add New
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className="selectFolderModal"
          show={this.state.isOpen5}
          onHide={this.closeModal5}
          backdrop="static"
        >
          <Modal.Header className="align-items-center border-0">
            <h5 className="modal-title">What do you want to create?</h5>
            <button
              type="button"
              className="close ml-5"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.closeModal5}
            >
              <span aria-hidden="true">
                <img src={close} alt="close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-wrap justify-content-center align-items-center text-center">
              <div className="folder mr-md-3" onClick={this.openCreateCoursesModal}>
                {/* <span><i className="fas fa-folder" /></span> */}
                <div className="folder-img">
                  <img src={agenda} className="agenda" />
                  <img src={agendaHover} className="agenda-hover" />
                </div>
                <p className="icon-title">Courses</p>
              </div>
              <div className="folder ml-md-3" onClick={this.openAddFileModal}>
                {/* <span><i className="fas fa-microphone" /></span> */}
                <div className="folder-img">
                  <img src={music} className="music" />
                  <img src={musicHover} className="music-hover" />
                </div>
                <p className="icon-title">Lessons</p>
              </div>
            </div>
            <div class="select-folder">
              <h3 class="title">Select Course</h3>
              <div class="scroll">{this.state.foldersPopup}</div>
              <div class="d-flex justify-content-center">
                <button class="btn btn-custom" onClick={this.loadLessons}>
                  Next
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          className="uploadAudioModal"
          show={this.state.isOpen6}
          onHide={this.closeModal6}
          backdrop="static"
        >
          <Modal.Header className="align-items-end border-0 pb-0">
            <div className="go-back" onClick={this.closeModal6}>
              <img src={goback} className="img-fluid mr-3" /> GO BACK
            </div>
            <button
              onClick={this.closeModal6}
              type="button"
              className="close ml-5"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <img src={close} />
              </span>
            </button>
          </Modal.Header>

          <h5 className="title">Upload Event files</h5>
          <h5 className="subtitle">{this.state.selectedTitle}</h5>
          {/*<small>Lesson Name</small>*/}
          <div className="modal-body">
            <form>
              <div className="upload-audio-file">
                <div className="form-group">
                  <label htmlFor="choose-file">
                    <img src={this.state.dragdrop} className="img-fluid" alt="drag-img" />
                  </label>
                  <input
                    type="file"
                    className="form-control lesson-control"
                    id="choose-file"
                    onChange={this.handleFileChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <div class="d-flex justify-content-center">
                  <button type="button" class="btn btn-custom" onClick={this.uploadLesson}>
                    Save & Add Lesson Details
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}
