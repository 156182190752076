import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Navbar, Col, Row, Card, Button, Container, Image } from "react-bootstrap";
import Loader from "../Loader";
import { commonUtil } from "../../helper/CommonUtils";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactReadMoreReadLess from "react-read-more-read-less";
import eventPlaceholder from "../../images/event-placeholder.png";
import coursePlaceholder from "../../images/course-placeholder.png";
import subscriptionPlaceholder from "../../images/subscription-placeholder.png";

import {
  subadminInfo,
  subadminSubscriptions,
  subscribefree,
  GetSalesPage, GetSalesPageEventsListForMember,
  GetSalesPageCoursesListForMember,
} from "../../helper/service";

const SalesPage = () => {
  const { subadminId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [membershipName, setMembershipName] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const [subscriptionData, setSubscriptionData] = useState("");
  const [selectedCourseData, setSelectedCourseData] = useState();
  const [selectedEventData, setSelectedEventData] = useState();
  const [brandColor, setBrandColor] = useState("");
  const [banner, setBanner] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [plansTitle, setPlanTitle] = useState("Explore Subscriptions");
  const [productTitle, setProductTitle] = useState("Explore Products");
  const [eventTitle, setEventTitle] = useState("Explore Events");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const data = await subadminInfo(subadminId);
        let brandColor;
        if (data?.result?.validUser) {
          brandColor = data?.result?.validUser.brandColor;
        }
        const salesPage = await GetSalesPage(subadminId);
        console.log(salesPage.result, "salesPage.result")
        if (salesPage.result.selectedCourse) {
          GetSalesPageCoursesListForMember(
            salesPage.result.selectedCourse
          ).then((courses) => {
            saleCoursesList(
              courses?.result?.selectedCoursesSale,
              brandColor
            );
          });
        }
        if (salesPage.result.selectedEvent) {
          GetSalesPageEventsListForMember(
            salesPage.result.selectedEvent
          ).then((events) => {
            saleEventsList(
              events?.result?.selectedEvantsSale,
              brandColor
            );
          });
        }
        if (!salesPage?.result?.isLive) {
          commonUtil.redirect("/");
        }

        if (salesPage?.result?.accessLevels) {
          //console.log(salesPage, "sales kkk");
          const subs = await subadminSubscriptions(subadminId);
          const newSubs = subs.result.filter(item => !item.hideMembershipLevel);
          subscriptionList(
            newSubs,
            salesPage.result.accessLevels,
            brandColor
          );

          setContent(salesPage.result.copy);
          setTitle(salesPage.result.title);
          setBanner(salesPage.result.image);
          setPlanTitle(
            salesPage?.result?.plansTitle || "Explore Subscriptions"
          );
          setProductTitle(
            salesPage?.result?.productsTitle || "Explore Products"
          );
          setEventTitle(
            salesPage?.result?.eventTitle || "Explore Events"
          );
          setEmail(data.result.validUser.email);
          setCountryCode(data.result.validUser.countryCode);
          setPhone(data.result.validUser.phoneNumber);
          setBrandColor(data.result.validUser.brandColor);
          localStorage.setItem(
            "partnerBrandColor",
            brandColor
          );
          setMembershipName(data.result.validUser.membershipName);

          var hasCompany =
            data.result.validUser.company &&
            data.result.validUser.company.length > 0;
          if (hasCompany) setCompanyName(data.result.validUser.company[0].name);

          var logo =
            hasCompany && data.result.validUser.company[0].logo
              ? data.result.validUser.company[0].logo
              : data.result.validUser.image;
          setLogo(logo);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const selectPlan = (planId, tier) => {
    let url = `/checkout/${subadminId}/${planId}/${tier}`;
    commonUtil.redirect(url);
  };
  const selectCourse = (courseId) => {
    let url = `/coursecheckout/${subadminId}/${courseId}/onetime`;
    commonUtil.redirect(url);
  };
  const selectEvent = (eventId) => {
    let url = `/eventcheckout/${subadminId}/${eventId}/onetime`;
    commonUtil.redirect(url);
  };

  const subscriptionList = (
    subscription,
    availableLevels,
    partnerBrandColor
  ) => {
    if (subscription) {
      var filteredSubscriptions = subscription.filter((item) =>
        availableLevels.includes(item._id)
      );
      const datas = filteredSubscriptions.map((subs) => {
        const monthlyFeeForYearlyPlan = (subs.amountYearly / 12).toFixed(2);
        return (
          <>
            <div className="col-lg-3 col-md-4 col-sm-6 col-sm-12 mb-3">
              <Card className="explore-plans-card">
                <Card.Body>
                  <div className="sales-page-image d-flex justify-content-center p-1">
                    <Image src={subs.thumbnailImg ?? subscriptionPlaceholder} alt="Event Image" className="img-fluid" />
                  </div>
                  <div className="plan-name">
                    <span>{subs.tier}</span>
                  </div>
                  <p className="mt-2 text-justify">{subs.description}</p>
                  {subs.amountYearly !== undefined &&
                    subs.amountYearly !== null &&
                    subs.amountYearly > 0 && (
                      <div className="text-center">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            minWidth: "300px",
                          }}
                          onClick={() => selectPlan(subs._id, "yearly")}
                        >
                          <span>
                            Purchase at {`$${monthlyFeeForYearlyPlan}`} AUD / Month
                          </span>
                          <br />
                          <span>
                            <small>Charged {`$${subs.amountYearly}`} / Yearly</small>
                          </span>
                        </Button>
                      </div>
                    )}
                  {subs.amountMonthly !== undefined &&
                    subs.amountMonthly !== null &&
                    subs.amountMonthly > 0 && (
                      <div className="text-center">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            minWidth: "300px",
                            marginTop: "10px",
                          }}
                          onClick={() => selectPlan(subs._id, "monthly")}
                        >
                          Purchase at {`$${subs.amountMonthly}`} AUD / Month
                        </Button>
                      </div>
                    )}
                  {(!subs.amountYearly || subs.amountYearly <= 0) &&
                    (!subs.amountMonthly || subs.amountMonthly <= 0) && (
                      <div className="text-center">
                        <Button
                          className="purchase-button"
                          style={{
                            backgroundColor: partnerBrandColor,
                            minWidth: "300px",
                          }}
                          onClick={() => selectPlan(subs._id, "yearly")}
                        >
                          Subscribe for Free
                        </Button>
                      </div>
                    )}
                </Card.Body>
              </Card>
            </div >
          </>
        );
      });
      setSubscriptionData(datas);
    } else {
      setSubscriptionData(null);
    }
  };

  const saleCoursesList = (courses, partnerBrandColor) => {

    const datas = courses.map((course) => {
      return (
        <>
          <div className="col-lg-3 col-md-4 col-sm-6 col-sm-12 mb-3">
            <Card className="explore-plans-card">
              <Card.Body>
                <div className="sales-page-image d-flex justify-content-center p-1">
                  <Image src={course.courseImage ?? coursePlaceholder} alt="Event Image" className="img-fluid" />
                </div>
                <div className="plan-name">
                  <span>{course.title}</span>
                </div>
                <p className="mt-2 text-justify">{course.shortDescription}</p>
                {course.amount !== undefined &&
                  course.amount !== null &&
                  course.amount > 0 ? (
                  <>
                    {/* <div className="text-center">
                                                <Button className='purchase-button' style={{ backgroundColor: `${localStorage.getItem("partnerBrandColor")}`, minWidth: '300px' }} onClick={() => selectPlan(subs._id, 'yearly')}>
                                                    <span>Purchase at {`$${monthlyFeeForYearlyPlan}`} AUD / Month</span>
                                                    <br></br>
                                                    <span><small>Charged {`$${subs.amountYearly}`} / Yearly </small></span>
                                                </Button>
                                            </div> */}
                    <div className="text-center">
                      <Button
                        className="purchase-button"
                        style={{
                          backgroundColor: partnerBrandColor,
                          minWidth: "300px",
                          marginTop: "10px",
                        }}
                        onClick={() => selectCourse(course._id)}
                      >
                        Purchase at {`$${course.amount}`}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      <Button
                        className="purchase-button"
                        style={{
                          backgroundColor: partnerBrandColor,
                          minWidth: "300px",
                        }}
                        onClick={() => selectCourse(course._id)}
                      >
                        Subscribe for Free
                      </Button>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </>
      );
    });
    setSelectedCourseData(datas);

  };
  
  const saleEventsList = (events, partnerBrandColor) => {
    const futureEvents = events.filter((event) => {
      return new Date(event?.eventDate) > new Date();
    });
    if (futureEvents?.length >= 0) {
      const datas = futureEvents.map((event) => {
        const eventStartDate = new Date(event.eventDate);
        const eventEndDate = new Date(event.eventEndDate);
        const startDate = eventStartDate.toLocaleDateString();
        const startTime = eventStartDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const endDate = eventEndDate.toLocaleDateString();
        const endTime = eventEndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 col-sm-12 mb-3">
            <Card className="explore-plans-card">
              <Card.Body>
                <div className="sales-page-image d-flex justify-content-center p-1">
                  <Image src={event.image ?? eventPlaceholder} alt="Event Image" className="img-fluid" />
                </div>
                <div className="plan-name">
                  <span>{event.name}</span>
                </div>

                <div className="mt-3 d-flex justify-content-between align-items-start flex-wrap">
                  <FontAwesomeIcon icon={faCalendar} className='mr-1' color={partnerBrandColor} />
                  <div className="event-date mr-auto">
                    {startDate} - {endDate} ({startTime} - {endTime})
                  </div>
                </div>
                <p className="mt-2 text-justify">
                  <ReactReadMoreReadLess
                    charLimit={442}
                    readMoreText={"Read more ▼"}
                    readLessText={"Read less ▲"}
                    readMoreStyle={{ color: partnerBrandColor, cursor: "pointer" }}
                    readLessStyle={{ color: partnerBrandColor, cursor: "pointer" }}
                  >
                    {event.description.replace(/<[^>]*>/g, '')}
                  </ReactReadMoreReadLess>
                </p>
                {event.eventAmount !== undefined &&
                  event.eventAmount !== null &&
                  event.eventAmount > 0 ?
                  <div className="text-center">
                    <Button
                      className="purchase-button"
                      style={{
                        backgroundColor: partnerBrandColor,
                        minWidth: "300px",
                        marginTop: "10px",
                      }}
                      onClick={() => selectEvent(event._id)}
                    >
                      Purchase at {`$${event.eventAmount}`}
                    </Button>
                  </div>
                  :
                  <div className="text-center">
                    <Button
                      className="purchase-button"
                      style={{
                        backgroundColor: partnerBrandColor,
                        minWidth: "300px",
                      }}
                      onClick={() => selectEvent(event._id)}
                    >
                      Subscribe for Free
                    </Button>
                  </div>
                }
              </Card.Body>
            </Card>
          </div>
        );
      });
      setSelectedEventData(datas);
      console.log(selectedEventData, "SELECT")
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      <Navbar
        expand="lg"
        className="sales-navbar"
        style={{ backgroundColor: brandColor }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
        >
          <Navbar.Brand href="#">
            <img
              src={logo}
              alt="Company Logo"
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Brand href="#">
            <div>
              <div style={{ color: "white" }}>{companyName}</div>
              <div style={{ color: "white" }}>{membershipName}</div>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"></Navbar.Collapse>
        </div>
      </Navbar>
      <div style={{ padding: "0 2em", width: "100%" }}>
        <section className="mt-3">
          <h4>{title}</h4>
          <img
            src={banner}
            alt="Sales banner"
            className="img-fluid"
            style={{ width: "100%" }}
          />
        </section>
        <section className="mt-3">
          <p className="text-justify"
            dangerouslySetInnerHTML={{ __html: content }}>
          </p>
        </section>

        <div className="d-flex align-items-center flex-wrap">
          <p className="returning-customer mb-0">
            Returning customer? <Link to="/login">Click here to login</Link>
          </p>
        </div>
        {subscriptionData?.length <= 0 && selectedCourseData?.length <= 0 && selectedEventData?.length <= 0 ? (
          <div className="mt-3">
            <h3>No products available</h3>
          </div>
        ) : (
          <>
            {selectedCourseData?.length > 0 ? (
              <>
                <div className="mt-3">
                  <h3>{productTitle}</h3>
                </div>
                <div className="row mt-3">{selectedCourseData}</div>
              </>
            ) : (
              <></>
            )}
            {selectedEventData?.length > 0 ? (
              <>
                <div className="mt-3">
                  <h3>{eventTitle}</h3>
                </div>
                <div className="row mt-3">{selectedEventData}</div>
              </>
            ) : (
              <></>
            )}
            {subscriptionData?.length > 0 ? (
              <>
                <div className="mt-3">
                  <h3>{plansTitle}</h3>
                </div>
                <div className="row mt-3">{subscriptionData}</div>
              </>
            ) : (
              <></>
            )}
          </>
        )}

      </div>
      <footer className="full-screen-footer mt-3">
        <Container fluid style={{ backgroundColor: brandColor }}>
          <Row className="justify-content-center">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center justify-content-lg-between"
            >
              <div className="column-content ml-5">
                <p
                  style={{
                    color: "white",
                    marginTop: "0.5em",
                    fontSize: "1.2rem",
                  }}
                >
                  {companyName}
                </p>
                <p style={{ color: "white", fontSize: "0.7rem" }}>
                  <a
                    target="_blank"
                    href={`/terms-and-conditions?subadminId=${subadminId}`}
                    style={{ color: "white" }}
                  >
                    {" "}
                    CLICK HERE FOR TERMS AND CONDITIONS{" "}
                  </a>
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center justify-content-lg-end"
            >
              <div className="column-content">
                <p
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    marginTop: "1.5rem",
                  }}
                >
                  PHONE: {countryCode} {phone}
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: "0.7rem",
                    marginBottom: "0rem",
                  }}
                >
                  EMAIL: {email}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default SalesPage;
