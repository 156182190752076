import React, { Component } from 'react';
import Group68026 from '../../images/admin/Group 68026.svg';
import Group68025 from '../../images/admin/Group 68025.svg';
import { Modal, Button } from "react-bootstrap";
import close from '../../images/dashboard/modal-close.svg';
import submit from '../../images/admin/submit.svg';
import $ from 'jquery';
import ReactPlayer from 'react-player';
import placeholder from '../../images/placeholder.jpg';
import defaultImg from "../../images/avatar.jpeg";
import SuccessPopup from "../Setting/SuccessPopup";
import Loader from '../Loader'
import ConfirmationModal from "../Setting/ConfirmationModal";
import Group72115 from '../../images/enduser/submit-button.svg';
import defaultStyle from '../../js/default.js'
import { coursecommentlistuser, saveCoursecommentuser, saveCoursecomment, coursecommentlist, getLessonById, challengelivelesson, challengedraftlesson, challengeschedulelesson, challengeinsidelesson, deleteChLessonCommentByUserId, editChallengeLessonCommentByUserId } from '../../helper/service'

export default class TopSearchResultComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            live: '',
            draft: '',
            schedule: '',
            deleteCommentId: null,
            deleteLessonId: null,
            modalType: '',
            showWarningModal: false,
            warningModalTitle: '',
            messageForContent: '',
            isSuccess: false,
            message: '',
            is_loader: false,
            commentToEditId: null,
            commentToEditValue: '',
            isEditModalVisible: false,
            commentsData: null,

        }
    }


    openModal = () => { this.setState({ isOpen: true }); console.log('called popup') };
    closeModal = () => this.setState({ isOpen: false });

    closeSuccessModal = () => {
        this.setState({ isSuccess: false });
    }
    listData() {
        console.log(this.props, 'props')
        const userId = localStorage.getItem('user_id');
        var lessonId = this.props.chlessonid;

        if (this.props.type === 'details') {
            console.log("inside if")
            challengeinsidelesson(lessonId).then(data => {
                const datas = {
                    res: data.result
                }
                console.log(datas, "data_lesson")
                this.iterateFolders(datas, 'live');
            })
            challengeschedulelesson(lessonId).then((data) => {
                const res = {
                    message: ' Exist',
                    status: 200,
                    res: data.result,
                }
                this.iterateFolders(res, 'schedule')
            })
        } else {
            console.log("inside else")
            challengedraftlesson(userId).then(data => {
                const datas = {
                    res: data.result
                }
                console.log(datas, "data_lesson")
                this.iterateFolders(datas, 'live');
            })
        }
    }

    async componentDidMount() {
        this.listData();
    }


    iterateFolders = async (datas, types) => {
        if (!datas.res.hasOwnProperty('status')) {
            var i = 0;
            var folders = datas.res.map(function (subs) {
                var currentDate = new Date();
                var scheduleDate = new Date(subs?.schedule);
                
                // let subsSchedule = subs?.schedule;
                // let subsCreatedDate = '';
                // let formattedDateTime = '';
                // if (subsSchedule) {
                //     var originalDate = new Date(subsSchedule);
                //     var selectedTimeZone = localStorage.getItem('websiteTimeZone');
                //     if (selectedTimeZone) { // Change this to your desired timezone
                //         subsCreatedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone });

                //         var currentDateTime = new Date();
                //         formattedDateTime = currentDateTime.toLocaleString('en-US', { timeZone: selectedTimeZone });
                //     }
                // }
                if (!subs.hideForMember) {
                    if (currentDate.getTime() > scheduleDate.getTime() || !subs?.schedule) {
                        i++;
                        return (
                            <tr key={subs._id}>
                                <td>
                                    <h3 class="table-title">{i}</h3>
                                </td>
                                <td>
                                    <h3 class="table-title">{subs.title}</h3>
                                </td>
                                <td>
                                    {subs.description}
                                </td>
                                <td>
                                    <button class="btn btn-live" onClick={this.lessonDetails.bind(this, subs._id)}>Preview</button>
                                </td>
                            </tr>
                        )
                    }
                }
            }, this)
            this.props.handleLessonCount(i);
        } else {
            var folders = "No records found.";
        }
        if (types == "live") { this.setState({ live: folders }) }
        if (types == "draft") { this.setState({ draft: folders }) }
        if (types == "schedule") { this.setState({ schedule: folders }) }
    }

    lessonDetails(lessonId) {
        getLessonById(lessonId).then(data => {
            if (data) {
                console.log(data, "preview_data")
                const supportedVideoFileTypes = ["mp4", "webm", "mov", "wmv", 'mpeg', "mkv", "ts", "avi", "wbem", 'x-ms-wma'];
                const supportedAudioFileTypes = ['wma', "mp3", "ogg", "oga", "wav", "aac",]

                if (data.result) {
                    var files = data.result.eventFile[0]['fileUrl'];
                    var fileName = files?.substring(files.lastIndexOf("/") + 1, files.lastIndexOf("."));

                    var fileExt = files?.substring(files.lastIndexOf('.') + 1);

                    var types = "";
                    if (fileExt == "png" || fileExt == "jpeg" || fileExt == "jpg" || fileExt == "heic" || fileExt == "webp") {
                        types = "image";
                    }
                    else if (supportedVideoFileTypes.includes(fileExt)) {
                        types = "video";
                    }
                    else if (supportedAudioFileTypes.includes(fileExt)) {
                        types = "audio";
                    }
                    this.coursecommentlist(lessonId);



                    const resource = data.result.newResource.map(function (
                        resource,
                    ) {
                        var filename = (resource.resourceUrl && resource.resourceUrl.split('/').pop()) || '';

                        return (
                            <div className="resourcesLists">
                                <a href={resource.resourceUrl} target="_blank">
                                    {decodeURIComponent(filename)}
                                </a>
                            </div>
                        )

                    },
                        this)
                    this.setState({
                        title: data.result.title,
                        description: data.result.description,
                        fileName: fileName,
                        types: types,
                        files: files,
                        currentLesson: lessonId,
                        currentCourse: data.result.challengeId._id,
                        resource: resource
                    })
                }
                this.openModal();
            }
        }, this)
    }

    async savevideocomment() {
        if (this.state.comment) {
            this.setState({ is_loader: true })
            let user_id = localStorage.getItem('user_id');
            console.log(user_id, "memberid")
            const commentData = {
                "lessonId": this.state.currentLesson,
                "courseId": this.state.currentCourse,
                "user_id": user_id,
                "comment": this.state.comment
            }
            await saveCoursecommentuser(commentData);
            this.coursecommentlist(this.state.currentLesson);
            this.setState({ is_loader: false, comment: '' })
        }
    }

    handleDelete = (commentId) => {
        const { currentLesson } = this.state;

        if (!currentLesson || !commentId) {
            console.error('Lesson ID or Comment ID is missing');
            return;
        }

        this.setState({
            message: "Do you really want to delete this comment?",
            modalType: 'delete',
            warningModalTitle: "Delete Confirmation",
            showWarningModal: true,
            deleteCommentId: commentId,
            deleteLessonId: currentLesson,
        });
    };

    confirmDelete = async () => {
        const { deleteLessonId, deleteCommentId } = this.state;
        if (deleteLessonId && deleteCommentId) {
            try {
                await deleteChLessonCommentByUserId(deleteLessonId, deleteCommentId);
                this.setState({
                    showWarningModal: false
                });
                this.coursecommentlist(deleteLessonId)
            } catch (error) {
                console.error("Error deleting comment:", error);
            }
        }
    };
    async coursecommentlist(idd) {
        try {
            const data = await coursecommentlistuser(idd);
            this.setState({ commentsData: data.result });
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    }


    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.savevideocomment();
        }
    }
    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleCommentEditSave();
        }
    };



    onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    handleEditComment = (commentId, commentValue) => {
        this.setState({
            commentToEditId: commentId,
            commentToEditValue: commentValue,
            isEditModalVisible: true,
        });
    };

    handleCommentEditChange = (event) => {
        this.setState({ commentToEditValue: event.target.value });
    }

    handleCommentEditSave = async () => {
        const { commentToEditId, commentToEditValue, currentLesson } = this.state;

        try {
            await editChallengeLessonCommentByUserId(currentLesson, commentToEditId, commentToEditValue);
            this.setState({
                commentToEditId: null,
                commentToEditValue: '',
                isEditModalVisible: false,
            }, () => {
                this.coursecommentlist(currentLesson);
            });
        } catch (error) {
            console.error("Failed to edit comment:", error);
            alert("There was an error saving your comment. Please try again.");
        }
    };

    handleCancelEdit = () => {
        this.setState({
            commentToEditId: null,
            commentToEditValue: '',
            isEditModalVisible: false,
        });
    }


    render() {
        const { commentsData, commentToEditId, commentToEditValue } = this.state;
        const user_id = localStorage.getItem('user_id');

        let commentElements;

        if (!commentsData || commentsData.commentByUserId.length === 0) {
            commentElements = <p>No records found.</p>;
        } else {
            commentElements = commentsData.commentByUserId.map((vid) => {
                let imgs = "";
                let key = vid.userId || vid.subadminId;

                if (key && key.company && key.company.length > 0 && key.company[0].logo) {
                    imgs = key.company[0].logo;
                } else if (key.image) {
                    imgs = key.image;
                } else {
                    imgs = placeholder;
                }

                const isEditing = commentToEditId === vid._id;

                return (
                    <div className="media" key={vid._id}>
                        <img src={imgs} className="user-img img-fluid mr-3" alt="User" />
                        <div className="media-body">
                            {key._id === user_id && (
                                <div className="dropdown dropleft">
                                    <i
                                        className="fas fa-ellipsis-v"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    />
                                    <div className="dropdown-menu">
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.handleEditComment(vid._id, vid.comment);
                                            }}
                                        >
                                            Edit
                                        </a>
                                        <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() => this.handleDelete(vid._id)}
                                        >
                                            Delete
                                        </a>
                                    </div>
                                </div>
                            )}
                            <h5 className="mt-0 messanger-name">
                                {key?.firstName} {key?.lastName}
                            </h5>
                            {isEditing ? (
                                <div>
                                    <input
                                        type="text"
                                        value={commentToEditValue}
                                        onChange={this.handleCommentEditChange}
                                        onKeyDown={this.handleKeyDown}
                                        className="form-control"
                                    />
                                    <div className="edit-buttons-container">
                                        <button
                                            onClick={this.handleCancelEdit}
                                            className="btn btn-cancel"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={this.handleCommentEditSave}
                                            className="btn btn-save text-white"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <p className="messanger-text">{vid.comment}</p>
                            )}
                        </div>
                    </div>
                );
            });
        }
        return (
            <section class="courses-list">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                            <ul class="nav nav-tabs" id="coursesTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="liveLessons-tab" data-toggle="tab" href="#liveLessons" role="tab" aria-controls="liveLessons" aria-selected="true">Latest Activity</a>
                                </li>

                                {/* <li class="nav-item" role="presentation">   
                                    <a class="nav-link" id="scheduledLessons-tab" data-toggle="tab" href="#scheduledLessons" role="tab" aria-controls="scheduledLessons" aria-selected="false">Scheduled Lessons</a>
                                </li> */}

                                {/* <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="draftLessons-tab" data-toggle="tab" href="#draftLessons" role="tab" aria-controls="draftLessons" aria-selected="false">Draft Lessons</a>
                                </li> */}
                            </ul>
                            <div class="tab-content" id="coursesTabContent">
                                <div class="tab-pane fade show active" id="liveLessons" role="tabpanel" aria-labelledby="liveLessons-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <tbody>
                                                {this.state.live}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="scheduledLessons" role="tabpanel" aria-labelledby="scheduledLessons-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <tbody>
                                                {this.state.schedule}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="draftLessons" role="tabpanel" aria-labelledby="draftLessons-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <tbody>
                                                {this.state.draft}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <Modal className="audioPreviewModal" show={this.state.isOpen} onHide={this.closeModal} backdrop="static">
                    <Modal.Header className="modal-header border-0 pb-0 align-items-center">
                        <h5 class="modal-title pl-0">Preview Activity</h5>
                        <button onClick={this.closeModal} type="button" className="close ml-5" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={close} /></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center mb-2">

                            {
                                (this.state.types == "image" ?
                                    <img src={this.state.files} class="img-fluid mx-auto d-block mb-2" height="180" />
                                    : '')
                            }

                            {
                                (this.state.types == "video" ?
                                    <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }} url={this.state.files} controls={true} playing={false} width="100%" height="353px" />
                                    : '')
                            }

                            {
                                (this.state.types == "audio" ?
                                    <ReactPlayer url={this.state.files} controls={true} playing={false} width="100%" height="50px" />
                                    : '')
                            }


                            {/* <label className='text-break'>{this.state.fileName}</label> */}
                        </div>
                        <h5 className='modal-title mr-0'>{this.state.title}</h5>
                        <p className='text-break'>{this.state.description}</p>
                        <h5 className="modal-title mr-0 mb-2">Resources</h5>
                        {console.log(this.state.resource, "challenges_resourcce")}
                        <div className="scroll">
                            <div className='resources mb-3'>
                                {(this.state.resource) != '' ? (this.state.resource) : 'Resource not available.'}
                            </div>
                        </div>
                        <div className="comments">

                            <div className="form-row mb-3 align-items-center">
                                <div className="col-10">
                                    <input
                                        type="text"
                                        className="form-control leave-comment"
                                        placeholder="Leave a comment"
                                        name="comment"
                                        onChange={this.onChange}
                                        onKeyPress={this.enterPressed.bind(this)}
                                        value={this.state.comment}
                                    />
                                </div>
                                <div className="col-2">
                                    <img
                                        src={submit}
                                        className="img-fluid comment-img"
                                        width={56}
                                        height={38}
                                        onClick={this.savevideocomment.bind(this)}
                                    />
                                </div>
                            </div>

                            <h4 className="title">
                                Comments
                                {/* <div className="custom-control custom-switch float-right">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="commentSwitch"
                            defaultChecked=""
                        />
                        <label className="custom-control-label" htmlFor="commentSwitch" />
                        </div> */}
                            </h4>
                            <div className="messages-list">
                                {commentElements}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


            </section>
        )
    }
}