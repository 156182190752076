import moduleUnavailable from "../../images/module-unavailable.png";

export default function ModuleNotAvailable(props) {
    return (
        <div className="container d-flex justify-content-center">
            <div className="row">
                <div className="col-lg-12">
                    <div className="access-denied">
                        <img src={moduleUnavailable} alt="Module unavailable" className="image image-fluid" style={{ width: 300 }} />
                        <h2>{props.title}</h2>
                        <p>This area is currently unavailable.
                            <a href={`mailto:${props.email}`}> Please contact your instructor.</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
