import axios from "axios";
import { commonUtil } from "./CommonUtils";

const BASEURL = commonUtil.BASEURL();

let register = async (data) => {
  const res = await axios.post(BASEURL + "user/register", data);
  if (res.status === 200) {
    let userData = res.data.result;
    let partnerId = localStorage.getItem("subadminId")
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("user_id", userData?._id);
    localStorage.setItem("firstName", userData.firstName);
    localStorage.setItem("lastName", userData.lastName);
    localStorage.setItem("email", userData.email);
    localStorage.setItem("mobile", userData.mobile);

    if (userData.subadminsId) {
      localStorage.setItem(
        "subadmins",
        JSON.stringify(userData.subadminsId)
      );
    }
    localStorage.setItem(
      "userData",
      JSON.stringify(userData)
    );
    let partnerIndex = userData.subadminsId.findIndex(subadmin => subadmin.subadminId._id == partnerId)
    if (userData.subadminsId && userData.subadminsId.length > 0) {
      localStorage.setItem(
        "subadminEmail",
        userData.subadminsId[partnerIndex]?.subadminId.email
      );
      localStorage.setItem(
        "subadminId",
        userData.subadminsId[partnerIndex]?.subadminId._id
      );
      localStorage.setItem(
        "subadminImage",
        userData.subadminsId[partnerIndex]?.subadminId.image
      );
      localStorage.setItem(
        "subadminFirstName",
        userData.subadminsId[partnerIndex]?.subadminId.firstName
      );
      localStorage.setItem(
        "subadminLastName",
        userData.subadminsId[partnerIndex]?.subadminId.lastName
      );
      localStorage.setItem(
        "subadminBrandColor",
        userData.subadminsId[partnerIndex]?.subadminId.brandColor
      );
      localStorage.setItem(
        "subadminBusiness",
        userData.subadminsId[partnerIndex]?.subadminId.businessName
      );
      localStorage.setItem(
        "membershipName",
        userData.subadminsId[partnerIndex]?.subadminId.membershipName
      );
      localStorage.setItem(
        "subadminLogo",
        userData.subadminsId[partnerIndex]?.subadminId.logo
      );
    }
  }

  return res.data;
};

const getConfig = () => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return configData;
};

let subadminregister = async (data) => {
  let formData = new FormData();
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("businessName", data.business);
  formData.append("email", data.email);
  formData.append("phoneNumber", data.phone);
  formData.append("password", data.password);
  formData.append("membershipName", data.membershipName);
  formData.append("logo", data.file);
  formData.append("brandColor", data.bgcolor);

  await axios
    .post(BASEURL + "subadmin/register", data)
    .then((res) => {
      // console.log(res);
      // return false;
      localStorage.setItem("email", res.data.result.email);
      if (res.status === 200) {
        commonUtil.redirect("/account-verification");
      }
    })
    .catch((error) => console.log(error));
};

let updateUser = async (data, id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: bearer },
  };
  let formData = new FormData();
  if (data.firstName) {
    formData.append("firstName", data.firstName);
  }
  if (data.mobile) {
    formData.append("mobile", data.mobile);
  }
  if (data.email) {
    formData.append("email", data.email);
  }
  if (data.image) {
    formData.append("image", data.image);
  }
  console.log(formData);
  return await axios
    .put(BASEURL + `user/${id}`, formData, config)
    .then((res) => res.data);
};

let login = async (data) => {
  try {
    const res = await axios.post(BASEURL + "user/login", data);
    if (res.status === 200) {
      let result = {
        success: true,
        token: res.data.token,
        user: res.data.result,
      };

      localStorage.setItem(
        "subadmins",
        JSON.stringify(res.data.result.subadminsId)
      );

      localStorage.setItem(
        "userData",
        JSON.stringify(res.data.result)
      );

      localStorage.setItem("email", res.data.result.email);
      localStorage.setItem("user_id", res.data.result._id);
      localStorage.setItem("firstName", res.data.result.firstName);
      localStorage.setItem("lastName", res.data.result.lastName);
      localStorage.setItem("subadminLogo", res.data.result.image);
      localStorage.setItem("token", res.data.token);

      if (
        res.data.result.subadminsId[0].subadminId.company &&
        res.data.result.subadminsId[0].subadminId.company.length > 0 &&
        res.data.result.subadminsId[0].subadminId.company[0].logo
      ) {
        localStorage.setItem(
          "subadminImage",
          res.data.result.subadminsId[0].subadminId.company[0].logo
        );
      } else {
        localStorage.setItem(
          "subadminImage",
          res.data.result.subadminsId[0].subadminId.image
        );
      }

      let subAdminColor =
        res.data.result.subadminsId[0]?.subadminId?.brandColor;
      if (subAdminColor && subAdminColor !== null && subAdminColor !== "null") {
        localStorage.setItem("subadminBrandColor", subAdminColor);
      } else {
        localStorage.setItem("subadminBrandColor", "#2F4A92");
      }
      return result;
    } else {
      return {
        success: false,
      };
    }
  } catch (error) {
    console.log("login error", error);
    return {
      success: false,
    };
  }
};

let memberlogin = async (data) => {
  await axios
    .post(BASEURL + "user/login", data)
    .then(async (res) => {
      if (res.status === 200) {
        localStorage.setItem("subadminEmail", res.data.result.subadminId.email);
        localStorage.setItem("subadminId", res.data.result.subadminId._id);
        localStorage.setItem(
          "subadminFirstName",
          res.data.result.subadminId.firstName
        );
        localStorage.setItem(
          "subadminLastName",
          res.data.result.subadminId.lastName
        );
        localStorage.setItem(
          "subadminBrandColor",
          res.data.result.subadminId.brandColor
        );
        localStorage.setItem(
          "subadminBusiness",
          res.data.result.subadminId.businessName
        );
        localStorage.setItem(
          "membershipName",
          res.data.result.subadminId.membershipName
        );
        localStorage.setItem("email", res.data.result.email);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user_id", res.data.result._id);
        localStorage.setItem("firstName", res.data.result.firstName);
        localStorage.setItem("lastName", res.data.result.lastName);
        localStorage.setItem("image", res.data.result.image);
        localStorage.setItem("subadminLogo", res.data.result.subadminId.logo);
        //const mobile = 9312367787;
        localStorage.setItem("mobile", res.data.result.mobile);

        if (
          res.data.result.subadminId.company &&
          res.data.result.subadminId.company.length > 0 &&
          res.data.result.subadminId.company[0].logo
        ) {
          localStorage.setItem(
            "subadminImage",
            res.data.result.subadminId.company[0].logo
          );
        } else {
          localStorage.setItem(
            "subadminImage",
            res.data.result.subadminId.image
          );
        }

        commonUtil.redirect("/welcome");
      } else {
        commonUtil.redirect("/membership-login");
      }
    })
    .catch((error) => {
      console.log(error);
      alert(error.message);
      // commonUtil.redirect("/home")
      console.log(error.message);
    });
};

async function forgotPwd(data) {
  try {
    const res = await axios.post(BASEURL + "user/forgetpwd", data);
    return res.data;
  } catch (error) {
    throw new Error("Email does not exist.");
    // alert('Email not exists')
  }
}

let verify = async (data) => {
  const datas = {
    accessId: data,
  };

  try {
    const res = await axios.put(BASEURL + "userInvite/verify", datas);

    if (res.status === 200) {
      if (res.data?.success || res.data?.result?.success) {
        let userData = res?.data?.result?.user;
        let subadminData = res?.data?.result?.subadmin;
        let subadminIndex = userData.subadminsId.findIndex(subadmin => subadmin.accessId == datas.accessId)
        if (userData?.subscriptionId?.[subadminIndex]?.status != "In-Active") {
          localStorage.setItem(
            "subscriptionId",
            userData?.subscriptionId?.[subadminIndex]?.subsId
          );
        }
        localStorage.setItem(
          "membershipName",
          subadminData.membershipName
        );
        localStorage.setItem(
          "companyName",
          subadminData.company[0]["name"]
        );
        localStorage.setItem("subadminEmail", subadminData.email);
        localStorage.setItem("subadminId", subadminData._id);
        localStorage.setItem("subadminImage", subadminData.image);
        localStorage.setItem("subadminLogo", subadminData.logo);
        localStorage.setItem(
          "subadminFirstName",
          subadminData.firstName
        );
        localStorage.setItem(
          "subadminLastName",
          subadminData.lastName
        );
        localStorage.setItem(
          "subadminBrandColor",
          subadminData.brandColor
        );
        localStorage.setItem(
          "subadminBusiness",
          subadminData.businessName
        );

        localStorage.setItem("password", userData.password);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("user_id", userData._id);
        localStorage.setItem("firstName", userData.firstName);
        localStorage.setItem("lastName", userData.lastName);
        localStorage.setItem("mobile", userData.mobile);
      }
    }
    return {
      success: res.data?.success || res.data?.result?.success,
      data: res.data,
    };
  } catch (error) {
    console.log("Error verifying user with access ID", error);
    return {
      success: false,
    };
  }
};

let getFolders = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .get(BASEURL + `folder/usergetAllFolder/${id}`, configData)
    .then((res) => res.data);
};

let getSubFolder = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .get(BASEURL + `folder/usergetAllSubFolder/${id}`, configData)
    .then((res) => res.data);
};

let getUser = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + `user/getuser/${id}`, configData)
    .then((res) => res.data);
};

let getAdminSubscriptions = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .get(BASEURL + `subs/subAdminId/${id}`, configData)
    .then((res) => res.data);
};

//TODO: rename function to match functionality + no reference to this method yet
let createPassword = async (password) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const datas = {
    password: password,
  };
  const user_id = localStorage.getItem("user_id");

  await axios
    .put(BASEURL + "user/changepwd/" + user_id, datas, configData)
    .then((res) => res.status);
};

let createPasswords = async (password) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const datas = {
    password: password,
  };
  const user_id = localStorage.getItem("user_id");
  await axios
    .put(BASEURL + "user/setpwd/" + user_id, datas, configData)
    .then((res) => res.status);
};

let createSocialPost = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("socialWallId", data.socialWallId);
  formData.append("description", data.description);
  formData.append("subadminId", userId);

  for (const key of Object.keys(data.imagesId)) {
    formData.append("imagesId", data.imagesId[key]);
  }

  return await axios
    .post(BASEURL + "post/register", formData, config)
    .then((res) => res);
};

let usercreateSocialPost = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { Authorization: bearer },
  };
  const userId = localStorage.getItem("user_id");
  var formData = new FormData();
  formData.append("socialWallId", data.socialWallId);
  formData.append("description", data.description);
  formData.append("userId", userId);
  formData.append("subadminId", data.subadminId);
  if (data.imagesId && Array.isArray(data.imagesId)) {
    data.imagesId.forEach((file) => {
      formData.append("imagesId", file);
    });
  }
  return await axios
    .post(BASEURL + "post/register", formData, config)
    .then((res) => res);
};

let getPostList = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "post/totalpostlist/" + idd, configData)
    .then((res) => res.data);
};

let createSocialWall = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    title: data1.title,
    description: data1.description,
    filterAudience: data1.filter,
    discussionTopic: data1.discussion,
    userId: data1.userId,
    subadminId: data1.subadminId,
  };

  return await axios
    .post(BASEURL + "socialwall/register", data, config)
    .then((res) => res);
};

let SocialList = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/totalsocialwall/" + userId, configData)
    .then((res) => res.data);
};

let getAllUsers = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/allusers/" + userId, configData)
    .then((res) => res.data);
};

let socialWallUsers = async (data) => {
  const token = localStorage.getItem("token");
  const subAdminId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + token;
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  try {
    const res = await axios.post(
      BASEURL + "userInvite/socialWallUsers/" + subAdminId,
      data,
      config
    );
    return res;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

let getLatestVideo = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/latestVideo/" + idd, configData)
    .then((res) => res.data);
};

let getVideoBySubadminId = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/getVideoBySubadminId/" + idd, configData)
    .then((res) => res.data);
};

let savecomment = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    videoId: data1.videoId,
    subAdminId: data1.subAdminId,
    commentByUserId: {
      userId: data1.userId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "video/comment/register", data, config)
    .then((res) => res);
};

let getcomments = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/totalcomment/" + idd, configData)
    .then((res) => res.data);
};

let getfolders = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "folder/usergetAllFolder/" + idd + "?contains=videos",
      configData
    )
    .then((res) => res.data);
};

let getContentfolders = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  let subadminId = localStorage.getItem("subadminId");
  let subscriptionId = localStorage.getItem("subscriptionId");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + `folder/usergetAllFolder/${subscriptionId}/${subadminId}?contains=contents`,
      configData
    )
    .then((res) => res.data);
};

let likevideo = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    videoId: data1.videoId,
    subAdminId: data1.subAdminId,
    likedByUserId: {
      userId: data1.userId,
    },
  };
  return await axios
    .post(BASEURL + "video/like/register", data, config)
    .then((res) => res);
};

let favoritevideo = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "video/userFavorite/" + idd, configData)
    .then((res) => res.data);
};

let getVideoByFolderId = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "video/getVideoByFolderId/" + idd + "?contains=videos",
      configData
    )
    .then((res) => res.data);
};

function getSubFolderList(folder, contains) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "folder/getAllSubFolder/" + folder + "?contains=" + contains,
      configData
    )
    .then((res) => res.data);
}

let getAudiofolders = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "folder/usergetAllFolder/" + idd + "?contains=audios",
      configData
    )
    .then((res) => res.data);
};

let getLatestAudio = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/latestAudio/" + idd, configData)
    .then((res) => res.data);
};

let favoriteaudio = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/userFavorite/" + idd, configData)
    .then((res) => res.data);
};

let getAudioBySubAdminId = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/getAudioBySubAdminId/" + idd, configData)
    .then((res) => res.data);
};

let getAudiocomments = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "audio/totalcomment/" + idd, configData)
    .then((res) => res.data);
};

let saveAudiocomment = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    audioId: data1.audioId,
    subAdminId: data1.subAdminId,
    commentByUserId: {
      userId: data1.userId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "audio/comment/register", data, config)
    .then((res) => res);
};

let getAudioByFolderId = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "audio/user/getAudioByFolderId/" + idd + "?contains=audios",
      configData
    )
    .then((res) => res.data);
};

let likeaudio = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    audioId: data1.audioId,
    subAdminId: data1.subAdminId,
    likedByUserId: {
      userId: data1.userId,
    },
  };
  return await axios
    .post(BASEURL + "audio/like/register", data, config)
    .then((res) => res);
};

let fetchSocialByUserId = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/user/" + idd, configData)
    .then((res) => res.data);
};

let getPostByWallId = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "post/totalpostlist/" + id, configData)
    .then((res) => res.data);
};

let savePostLikeData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    postId: data1.postId,
    socialWallId: data1.socialWallId,
    likedByUserId: { userId: data1.userId },
  };

  return await axios
    .post(BASEURL + "post/like/register", data, config)
    .then((res) => res);
};

let savePostcommentData = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    postId: data1.postId,
    socialWallId: data1.socialWallId,
    subadminId: localStorage.getItem("subadminId"),
    commentByUserId: {
      userId: data1.userId,
      comment: data1.comment,
    },
  };

  return await axios
    .post(BASEURL + "post/comment/register", data, config)
    .then((res) => res);
};

let getSocialWallById = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/" + id, configData)
    .then((res) => res.data);
};

let deletePost = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .delete(BASEURL + "post/" + idd, configData)
    .then((res) => res.data);
};

let LatestSocialList = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const subscriptionId = localStorage.getItem("subscriptionId");
  const subadminId = localStorage.getItem("subadminId");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/user/recent/" + idd + "/" + subscriptionId + "/" + subadminId, configData)
    .then((res) => res.data);
};

let PopularSocialList = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const subscriptionId = localStorage.getItem("subscriptionId");
  const subadminId = localStorage.getItem("subadminId");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "socialwall/user/popularpost/" + idd + "/" + subscriptionId + "/" + subadminId, configData)
    .then((res) => res.data);
};
let PopularSocialListOnCourseBase = async (idd, courseId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const subadminId = localStorage.getItem("subadminId");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let data = { courseId }
  return axios
    .post(BASEURL + "socialwall/user/popularpost/" + idd + "/" + subadminId, data, configData)
    .then((res) => res.data);
};

function userInfo(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/user/" + userId, configData)
    .then((res) => res.data);
}
function userPurcahseCourse(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/getPurcahseCourses/" + userId, configData)
    .then((res) => res.data);
}
function getSocialOfPurcahseCourse(data, userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .post(BASEURL + "user/getPurcahseCoursesSocialwall/" + userId, data, configData)
    .then((res) => res.data);
}
function userPurcahseCourseSocialWall(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/getPurcahseCoursesSocialWall/" + userId, configData)
    .then((res) => res.data);
}
function getMemberData(email) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/getUserByEmail/" + email, configData)
    .then((res) => res.data);
}

function subadminInfo(userId) {
  const configData = {
    headers: { "content-type": "application/json" },
  };

  return axios
    .get(BASEURL + "subadmin/getSubadminInfo/" + userId, configData)
    .then((res) => {
      return res.data;
    });
}

let updatePassword = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const datas = {
    newPassword: data.newPassword,
    confirmPassword: data.conPassword,
    password: data.currentPassword,
  };

  const user_id = localStorage.getItem("user_id");
  console.log(datas, "payload_data");

  const res = await axios.put(BASEURL + "user/changepwd/" + user_id, datas, configData)
  return res
};

let updateAccount = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: bearer },
  };

  let formData = new FormData();
  formData.append("firstName", data.first_name);
  formData.append("lastName", data.last_name);
  formData.append("mobile", data.phone);
  if (data.img) {
    formData.append("image", data.img);
  }
  const user_id = localStorage.getItem("user_id");

  const res = await axios.put(BASEURL + "user/" + user_id, formData, config)
  return res;
};

function usersubscription(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios.get(BASEURL + "subs", configData).then((res) => res.data);
}

function usersubscriptionData() {
  const bearer = "Bearer " + localStorage.getItem("token");
  const subadminId = localStorage.getItem("subadminId");

  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + subadminId, configData)
    .then((res) => res.data);
}

function subadminSubscriptions(subadminId) {
  const bearer = "Bearer " + localStorage.getItem("token");

  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "subs/subAdminId/" + subadminId, configData)
    .then((res) => res.data);
}

function getMembershipLevel(membershipLevelId) {
  return axios
    .get(BASEURL + "subs/" + membershipLevelId, getConfig())
    .then((res) => res.data);
}
function getSaleSelectedCourse(courseId) {
  return axios
    .get(BASEURL + "courses/" + courseId, getConfig())
    .then((res) => res.data);
}
function getSaleSelectedEvent(eventId) {
  return axios
    .get(BASEURL + "events/" + eventId, getConfig())
    .then((res) => res.data);
}

// let updateNotificationStatus = async (data1) => {
//   const bearer = "Bearer " + localStorage.getItem('token');
//   const config = {
//     headers: { 'content-type': 'application/json', 'Authorization': bearer }
//   }
//     const data = {
//         "_id": data1.id,
//         "status": data1.status
//     }
//   return await axios.put(BASEURL + "user/notification/library/"+data1.userId, data, config).then(res => res)
// }

let updateNotificationStatus = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    status: data1.status,
    identityValue: data1.identityValue,
  };
  return await axios
    .put(BASEURL + "setting/notification/update/" + data1.id, data, config)
    .then((res) => res);
};

let updateAppUpdateNotificationStatus = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    _id: data1.id,
    status: data1.status,
  };
  return await axios
    .put(BASEURL + "user/notification/app/" + data1.userId, data, config)
    .then((res) => res);
};

let updateUnsubscribePolicy = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const data = {
    unsubscribeDescription: [{ text: data1.text }],
  };
  return await axios
    .put(BASEURL + "subadmin/subscribe/register/" + data1.userId, data, config)
    .then((res) => res);
};

function getUnSubscribe(userId, adminId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/TermsCondition/" + userId + "/" + adminId, configData)
    .then((res) => res.data);
}

let UnSubscribeNow = async (idd, comment) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  let subadminId = localStorage.getItem("subadminId");
  let subsId = localStorage.getItem("subscriptionId");

  const data = {
    unsubscribeSelection: idd,
    unsubscribeStatus: "Active",
    unsubscribeDescription: comment,
  };

  return await axios
    .post(
      BASEURL + "user/subscribe/status/" + userId + "/" + subadminId + "/" + subsId,
      data,
      config
    )
    .then((res) => res);
};
let changeFreeSubsModelStatus = async () => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const userId = localStorage.getItem("user_id");
  let subadminId = localStorage.getItem("subadminId");
  let subsId = localStorage.getItem("subscriptionId");


  return await axios
    .put(
      BASEURL + "user/changesubsmodelstatus/" + subadminId + "/" + subsId + "/" + userId,
      config
    )
    .then((res) => res);
};

function getAllNotification(userId) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "setting/notification", configData)
    .then((res) => res.data);
}

function createPaypalTransaction(datas) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .post(BASEURL + "user/upgradePaypalTransaction", datas, configData)
    .then((res) => res.data);
}

let getLatestContent = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/latestContent/" + idd, configData)
    .then((res) => res.data);
};

let getContentByFolderId = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const subscriptionId = localStorage.getItem("subscriptionId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "content/getContentByFolderId/" + idd + "/" + userId + "/" + subscriptionId,
      configData
    )
    .then((res) => res.data);
};

let getContent = (id) => {
  const token = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: token },
  };
  return axios
    .get(BASEURL + `content/getContent/${id}`, configData)
    .then((res) => res.data);
};

let getRecentContent = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/recentContent/" + idd, configData)
    .then((res) => res.data);
};

let subscribefree = async (data) => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .post(BASEURL + "user/upgradeFreeSubs/" + userId, data, config)
    .then((res) => res);
};

let getAllInvoices = async () => {
  const userId = localStorage.getItem("user_id");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "user/getAllInvoices/" + userId, configData)
    .then((res) => res.data);
};

let getAllEvents = async (idd) => {
  const userId = localStorage.getItem("user_id");
  const subscriptionId = localStorage.getItem("subscriptionId");
  const subadminId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/eventForUser/" + userId + "/" + subadminId + "/" + subscriptionId, configData)
    .then((res) => res.data);
};
let getSelectedEvent = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/" + idd, configData)
    .then((res) => res.data);
};

function livelesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/live_lessons/" + idd, configData)
    .then((res) => res.data);
}

function schedulelesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/scheduled_lessons/" + idd, configData)
    .then((res) => res.data);
}

function draftlesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/draftlessons/" + idd, configData)
    .then((res) => res.data);
}

function totalCourses() {
  const userId = localStorage.getItem("subadminId");
  //const userId = localStorage.getItem('user_id');
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/totalcourses/" + userId, configData)
    .then((res) => res.data);
}

let getRecentCourses = async () => {
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId);
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(BASEURL + "courses/recentCoursesForuser/" + userId, configData)
    .then((res) => res.data);
};

let getAllCourses = async () => {
  const userId = localStorage.getItem("subadminId");
  const subsId = localStorage.getItem("subscriptionId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(BASEURL + `courses/allCoursesForuser/${userId}/${subsId}`, configData)
    .then((res) => res.data);
};

const enrollCourse = async (courseId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let enrolSocialWallUserId = {
    userid: localStorage.getItem("user_id"),
    subadminId: localStorage.getItem("subadminId")
  };
  return await axios
    .put(
      BASEURL + "courses/enrollUser/" + courseId,
      enrolSocialWallUserId,
      config
    )
    .then((res) => res.data);
};

const unenrollCourse = async (courseId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let enrollSocialWallUserId = {
    userid: localStorage.getItem("user_id"),
    subadminId: localStorage.getItem("subadminId"),
  };
  return await axios
    .put(
      BASEURL + "courses/unenroll/" + courseId,
      enrollSocialWallUserId,
      config
    )
    .then((res) => res.data);
};

const getEnrolledCourses = async () => {
  let subsId = localStorage.getItem('subscriptionId')
  let subadminId = localStorage.getItem('subadminId')
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + "courses/enrolled/courses/" + subsId + "/" + subadminId, config)
    .then((res) => res.data);
};

function courseLessons(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  let subsId = localStorage.getItem("subscriptionId");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/totalLessonCount/" + idd + "/" + subsId, configData)
    .then((res) => res.data);
}
function purchasecourseLessons(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "lesson/purchasecourseLessons/totalLessonCount/" + idd,
      configData
    )
    .then((res) => res.data);
}
function getCourseModule(data) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  let dataByModule = {
    courseId: data.courseId,
    subAdminId: data.partnerId,
    memberAccessLevel: data.memberAccessLevel,
    memberId: data.memberId,
  };
  console.log(dataByModule, "formDataformData");
  return axios
    .post(
      BASEURL + "coursemodule/bycourse/memberModule/" + data.courseId,
      dataByModule,
      configData
    )
    .then((res) => res.data);
}
function getCourseModulePurchaseCourse(data) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  let dataByModule = {
    courseId: data.courseId,
    subAdminId: data.partnerId,
    //    memberAccessLevel: data.memberAccessLevel,
    memberId: data.memberId,
  };
  console.log(dataByModule, "formDataformData");
  return axios
    .post(
      BASEURL +
      "coursemodule/bycourse/courseModuleOfPurchaseCourse/" +
      data.courseId,
      dataByModule,
      configData
    )
    .then((res) => res.data);
}
let getModule = async (moduleId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + "coursemodule/bycourse/memberModule/" + moduleId, config)
    .then((res) => res);
};
const getLiveLessonsModules = async (userId, courseId, moduleId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let apiPath = "lesson/live_lessons/" + userId;
  if (courseId) {
    apiPath += "/" + courseId;
  }
  if (moduleId) {
    apiPath += "/" + moduleId;
  }
  let data = {
    userid: localStorage.getItem("user_id"),
    subsId: localStorage.getItem("subscriptionId"),
  };
  return await axios
    .post(BASEURL + apiPath, data, configData)
    .then((res) => res.data);
};

let getScheduledCourses = async () => {
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId);
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/scheduled_courses/" + userId, configData)
    .then((res) => res.data);
};

let getTopCourses = async () => {
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId);
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/recentCoursesForuser/" + userId, configData)
    .then((res) => res.data);
};

function totalLessonCount(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/totalLessonCount/" + idd, configData)
    .then((res) => res.data);
}

function getLessonsByCourseId(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/" + idd, configData)
    .then((res) => res.data);
}

function courseDetailsApi(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/recentCoursesForuser/" + idd, configData)
    .then((res) => res.data);
}

let getRecentChallenges = async () => {
  const partnerId = localStorage.getItem("subadminId");
  const subsId = localStorage.getItem("subscriptionId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + `challenge/recentChallengesForuser/${partnerId}/${subsId}`, configData)
    .then((res) => res.data);
};

function getLessonById(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chLesson/" + idd, configData)
    .then((res) => res.data);
}

let coursecommentlist = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/comment/total/" + idd, configData)
    .then((res) => res.data);
};

let saveCoursecomment = async (d) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    lessonId: d.lessonId,
    courseId: d.courseId,
    commentByUserId: {
      userId: d.commentByUserId.userId,
      comment: d.commentByUserId.comment,
    },
  };
  return await axios
    .post(BASEURL + "lesson/comment/register", data, config)
    .then((res) => res);
};

let getScheduledChallenges = async () => {
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId);
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/topChallanges/" + userId, configData)
    .then((res) => res.data);
};

let getTopChallenges = async () => {
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId);
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/scheduleChallenges/" + userId, configData)
    .then((res) => res.data);
};

function totalChallenges() {
  //const userId = localStorage.getItem('subadminId');
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/totalChallenges/" + userId, configData)
    .then((res) => res.data);
}

function challengesDetailsApi(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  const partnerId = localStorage.getItem("subadminId");
  const subsId = localStorage.getItem("subscriptionId");
  return axios
    .get(BASEURL + `challenge/recentChallengesForuser/${partnerId}/${subsId}`, configData)
    .then((res) => res.data);
}

let getAllUpcomingEvents = async (idd) => {
  const userId = localStorage.getItem("subadminId");
  const subscriptionId = localStorage.getItem("subscriptionId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/upcomingEvents/" + userId + "/" + subscriptionId, configData)
    .then((res) => res.data);
};

let eventDetails = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/" + idd, configData)
    .then((res) => res.data);
};

let saveCommentData = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "events/comment/register", data, config)
    .then((res) => res);
};
const SendEmailUsermember = async (data) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios
    .post(
      `${BASEURL}userInvite/SendEmailUsermember/${data.subadminEmail}`,
      data,
      config
    )
    .then((res) => {
      return res;
    });
};
function challengelivelesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/live_lessons/" + idd, configData)
    .then((res) => res.data);
}

function challengeschedulelesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/scheduled_lessons/" + idd, configData)
    .then((res) => res.data);
}

function challengedraftlesson(idd) {
  var adminId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/draftlessons/" + adminId, configData)
    .then((res) => res.data);
}

function challengeinsidelesson(idd) {
  const partnerId = localStorage.getItem("subadminId");
  const subsId = localStorage.getItem("subscriptionId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + `chLesson/user/totalLessonOfCourse/${idd}/${partnerId}/${subsId}`, configData)
    .then((res) => res.data);
}

function getAllCommentsofEvents(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "events/totalcomment/" + idd, configData)
    .then((res) => res.data);
}

// http://localhost:9000/api/content/MembershipLevel/618e006027a61e5da7508226

let getContentBySubs = async (idd) => {
  const subscriptionId = localStorage.getItem("subscriptionId");
  //subsId = localStorage.getItem('subscriptionId');

  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "content/MembershipLevel/" + subscriptionId, configData)
    .then((res) => res.data);
};

function CourseLatestlesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/user/totalLessonOfCourse/" + idd, configData)
    .then((res) => res.data);
}

function CourseSchedulelesson(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/scheduled_lessonsOfOneCourse/" + idd, configData)
    .then((res) => res.data);
}

function getLatestLesson(idd) {
  const userId = localStorage.getItem("user_id");
  var adminId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "lesson/latestLessonAtUserEnd/" + adminId + "/" + userId,
      configData
    )
    .then((res) => res.data);
}

function getScheduleLesson(idd) {
  const userId = localStorage.getItem("user_id");
  var adminId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(
      BASEURL + "lesson/scheduledLessonAtUserEnd/" + adminId + "/" + userId,
      configData
    )
    .then((res) => res.data);
}

//TODO: try better function name
let updateAdminForgotPassword = async (data1, token) => {
  const bearer = "Bearer " + token;
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    newPassword: data1.newPassword,
    confirmPassword: data1.conPassword,
  };


  return await axios
    .put(BASEURL + 'user/setpwd/forgetPwd/' + data1.userId, data, config)
    .then((res) => res);
};
let geteEnrolledWallMemById = async (id) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + "userInvite/getEnrolledStudentById/" + id, configData)

    .then((res) => res.data);
};

let saveCoursecommentuser = async (data1) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  let user_id = localStorage.getItem("user_id");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  const data = {
    lessonId: data1.lessonId,
    challengeId: data1.courseId,

    commentByUserId: {
      userId: user_id,
      comment: data1.comment,
    },
  };
  console.log(data, "data sending by us");
  return await axios
    .post(BASEURL + "chLesson/comment/register", data, config)
    .then((res) => res);
};

let coursecommentlistuser = async (idd) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "chlesson/comment/total/" + idd, configData)
    .then((res) => res.data);
};

function getCourseLessonById(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "lesson/" + idd, configData)
    .then((res) => res.data);
}
let courseSnapshot = async (courseId) => {
  const subadminId = localStorage.getItem("subadminId");
  const userId = localStorage.getItem("user_id");
  const subsId = localStorage.getItem("subscriptionId");
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return axios
    .get(BASEURL + `courses/courseSnapshot/${subadminId}/${subsId}/${courseId}/${userId}`, configData)
    .then((res) => res.data);
}

function getCourseById(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "courses/" + idd, configData)
    .then((res) => res.data);
}

function totalLessonOfCourse(idd) {
  const bearer = "Bearer " + localStorage.getItem("token");
  const subsId = localStorage.getItem("subscriptionId");
  const subadminId = localStorage.getItem("subadminId");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + `lesson/user/totalLessonOfCourse/${idd}/${subsId}/${subadminId}`, configData)
    .then((res) => res.data);
}

let getRecentChallenges1 = async () => {
  // eslint-disable-line
  const userId = localStorage.getItem("subadminId");
  const bearer = "Bearer " + localStorage.getItem("token");
  var formData = new FormData();
  formData.append("subadminId", userId);
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "challenge/recentChallengesForuser/" + userId, configData)
    .then((res) => res.data);
};
let startOrCompleteLesson = async (startCompleteData) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .patch(
      BASEURL + "lesson/startCompleteLesson/" + startCompleteData.lessonId,
      startCompleteData,
      config
    )
    .then((res) => res);
};
let getNextLessonDetails = async (prevLessonId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .get(BASEURL + "lesson/nextViewLesson/" + prevLessonId, config)
    .then((res) => res);
};
let getNextOrPrevLessonDirect = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .post(BASEURL + "lesson/nextOrPreviousLesson", data, config)
    .then((res) => res);
};
let startOrCompleteContent = async (contentId, startCompleteData) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .put(
      BASEURL + "content/startAndCompleteContent/" + contentId,
      startCompleteData,
      config
    )
    .then((res) => res);
};
let getNextContent = async (prevcontentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + "content/nextContent/" + prevcontentId, config)
    .then((res) => res);
};

const enrollChallenge = async (challengeId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  let enrolSocialWallUserId = {
    userid: localStorage.getItem("user_id"),
    subadminId: localStorage.getItem("subadminId"),
  };
  return await axios
    .put(
      BASEURL + "challenge/enrollUser/" + challengeId,
      enrolSocialWallUserId,
      config
    )
    .then((res) => res.data);
};

/**
 *
 * @param {object} events
 * @param {string} events.hostedData
 */
const verifyAndCompleteSubscription = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");

  return await axios
    .post(
      BASEURL + "user/verifyAndCompleteSubscription/" + userId,
      data,
      config
    )
    .then((res) => res.data);
};
const unsubscribeEmailMember = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");

  return await axios
    .post(
      BASEURL + "user/unsubscribeEmail",
      data,
      config
    )
    .then((res) => res.data);
};
const verifyAndCompleteCourse = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");

  return await axios
    .post(BASEURL + "user/verifyAndCompleteCourse/" + userId, data, config)
    .then((res) => res.data);
};

const verifyAndCompleteEvent = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");
  var subadminId = localStorage.getItem("subadminId");

  return await axios
    .post(BASEURL + "user/verifyAndCompleteEvent/" + userId + "/" + subadminId, data, config)
    .then((res) => res.data);
};

const createMemberPayment = async (subscriptionId, subscriptionType, coupon) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");
  let data = {
    subadminId: localStorage.getItem("subadminId"),
    couponCode: coupon
  }

  return await axios
    .post(
      BASEURL +
      "payment/creatememberpayment/" +
      subscriptionId +
      "/" +
      subscriptionType +
      "/" +
      userId,
      data,
      config
    )
    .then((res) => res.data);
};
const createMemberCoursePayment = async (courseId, courseType, coupon = "") => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");
  let data = {
    subadminId: localStorage.getItem("subadminId"),
    couponCode: coupon
  }

  return await axios
    .post(
      BASEURL +
      "payment/createmembercoursepayment/" +
      courseId +
      "/" +
      courseType +
      "/" +
      userId,
      data,
      config
    )
    .then((res) => res.data);
};

const createMemberEventPayment = async (eventId, eventType, coupon) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");
  let data = {
    subadminId: localStorage.getItem("subadminId"),
    couponCode: coupon,
  }


  return await axios
    .post(
      BASEURL +
      "payment/createmembereventpayment/" +
      eventId +
      "/" +
      eventType +
      "/" +
      userId,
      data,
      config
    )
    .then((res) => res.data);
};

const startSubscription = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");

  return await axios
    .post(BASEURL + "user/startSubscription/" + userId, data, config)
    .then((res) => res.data);
};
const courseLessonCount = async (courseId) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return await axios
    .get(BASEURL + "courses/getCourseLessonCount/" + courseId, config)
    .then((res) => res.data);
};
const startPurchaseCourse = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");

  return await axios
    .post(BASEURL + "user/startCourse/" + userId, data, config)
    .then((res) => res.data);
};
const startPurchaseEvent = async (data) => {
  const bearer = "Bearer " + localStorage.getItem("token");

  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  var userId = localStorage.getItem("user_id");

  return await axios
    .post(BASEURL + "user/purchaseEvent/" + userId, data, config)
    .then((res) => res.data);
};

const joinEvent = async (eventId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  let subsId = localStorage.getItem('subscriptionId');
  const config = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  return await axios
    .patch(BASEURL + `events/${eventId}/join/${subsId}`, {}, config)
    .then((res) => res.data);
};

function GetSalesPage(subadminId) {
  const config = {
    headers: { "content-type": "application/json" },
  };
  return axios
    .get(BASEURL + "subAdmin/salespage/" + subadminId, config)
    .then((res) => res.data);
}
function GetSalesPageCoursesListForMember(data) {
  const config = {
    headers: { "content-type": "application/json" },
  };
  return axios
    .post(BASEURL + "subAdmin/salesPageCoursesForMember", data, config)
    .then((res) => res.data);
}
function GetSalesPageEventsListForMember(data) {
  console.log(data,"DATATAA")
  const config = {
    headers: { "content-type": "application/json" },
  };
  return axios
    .post(BASEURL + "subAdmin/salesPageEventsForMember", data, config)
    .then((res) => res.data);
}
function GetSalesPageCoursesListSocialWall(data) {
  const config = {
    headers: { "content-type": "application/json" },
  };
  return axios
    .post(BASEURL + "subAdmin/salesPageSocialWallForMember", data, config)
    .then((res) => res.data);
}

let addSingleMember = async (data) => {
  const config = {
    headers: { "content-type": "application/json" },
  };
  var formData = {
    memberFirstName: data.firstName,
    memberLastName: data.lastName,
    memberEmail: data.email,
    memberNumber: data.mobile,
    memberPackage: data.memberPackage,
    subsType: data.subsType,
  };
  return await axios
    .post(
      BASEURL + "userInvite/uploadSingleUser/" + data.subadminId,
      formData,
      config
    )
    .then((res) => {
      return res;
    });
};
let deleteChLessonCommentByUserId = async (lessonId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}chlesson/deletechallengecommentbyuserid/${lessonId}/${commentId}`,
      configData
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};
let deleteEventCommentByUserId = async (lessonId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}events/deleteeventcommentbyuserid/${lessonId}/${commentId}`,
      configData
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};
let deleteCourseLessonCommentByUserId = async (lessonId, commentId, user) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}lesson/deletecourselessoncommentbyuserid/${user}/${lessonId}/${commentId}`,
      configData
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};
let deletePostCommentByUserId = async (postId, commentId) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  try {
    const response = await axios.delete(
      `${BASEURL}post/deletepostcommentbyuserid/${postId}/${commentId}`,
      configData
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};
let editEventCommentByUserId = async (eventId, commentId, updatedComment) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment) {
    throw new Error("Updated comment text is required");
  }

  try {
    const response = await axios.put(
      `${BASEURL}events/editeventcommentbyuserid/${eventId}/${commentId}`,
      { updatedComment: updatedComment },
      configData
    );
    return response.data;
  } catch (error) {
    console.error("Error editing comment:", error.response ? error.response.data : error.message);
    throw error;
  }
};
let editChallengeLessonCommentByUserId = async (lessonId, commentId, updatedComment) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment || updatedComment.trim() === "") {
    throw new Error("Updated comment text is required");
  }

  try {
    console.log("Edited Comment Passed to Service:", updatedComment);
    const response = await axios.put(
      `${BASEURL}chlesson/editchallengelessoncommentbyuserid/${lessonId}/${commentId}`,
      { updatedComment: updatedComment.trim() },
      configData
    );
    console.log("Response from the server:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error editing comment:", error.response ? error.response.data : error.message);
    throw error;
  }
};
let editPostCommentByUserId = async (postId, commentId, updatedComment) => {
  const bearer = "Bearer " + localStorage.getItem("token");
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };

  if (!updatedComment || updatedComment.trim() === "") {
    throw new Error("Updated comment text is required");
  }

  try {
    console.log("Edited Comment Passed to Service:", updatedComment);
    const response = await axios.put(
      `${BASEURL}post/editpostcommentbyuserid/${postId}/${commentId}`,
      { updatedComment: updatedComment.trim() },
      configData
    );
    console.log("Response from the server:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error editing comment:", error.response ? error.response.data : error.message);
    throw error;
  }
};

function subadminAllDataCount() {
  const bearer = "Bearer " + localStorage.getItem("token");
  const partnerId = localStorage.getItem("subadminId")
  const configData = {
    headers: { "content-type": "application/json", Authorization: bearer },
  };
  return axios
    .get(BASEURL + "sasubscription/subadminalldata/" + partnerId, configData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error("API error:", err);
      throw err;
    });
}

export {
  startOrCompleteContent,
  startOrCompleteLesson,
  totalLessonOfCourse,
  getTopCourses,
  getCourseLessonById,
  SendEmailUsermember,
  getCourseById,
  coursecommentlistuser,
  saveCoursecommentuser,
  updateAdminForgotPassword,
  getScheduleLesson,
  getLatestLesson,
  CourseLatestlesson,
  CourseSchedulelesson,
  courseLessons,
  getContentBySubs,
  getAllCommentsofEvents,
  challengelivelesson,
  challengeschedulelesson,
  challengedraftlesson,
  challengeinsidelesson,
  saveCommentData,
  eventDetails,
  purchasecourseLessons,
  getAllUpcomingEvents,
  challengesDetailsApi,
  totalChallenges,
  getScheduledChallenges,
  GetSalesPageCoursesListForMember,
  getTopChallenges,
  coursecommentlist,
  saveCoursecomment,
  getLessonById,
  getRecentChallenges,
  courseDetailsApi,
  getLessonsByCourseId,
  totalLessonCount,
  getRecentCourses,
  getScheduledCourses,
  totalCourses,
  livelesson,
  verifyAndCompleteCourse,
  schedulelesson,
  draftlesson,
  getNextContent,
  getAllEvents,
  subscribefree,
  getRecentContent,
  getContent,
  getContentByFolderId,
  getLatestContent,
  getContentfolders,
  createPaypalTransaction,
  usersubscriptionData,
  subadminSubscriptions,
  getAllNotification,
  createPasswords,
  UnSubscribeNow,
  getUnSubscribe,
  updateUnsubscribePolicy,
  updateNotificationStatus,
  updateAppUpdateNotificationStatus,
  userPurcahseCourseSocialWall,
  usersubscription,
  updateAccount,
  getNextLessonDetails,
  getCourseModulePurchaseCourse,
  updatePassword,
  userInfo,
  subadminInfo,
  verifyAndCompleteEvent,
  userPurcahseCourse,
  PopularSocialList,
  LatestSocialList,
  startPurchaseCourse,
  deletePost,
  getSocialWallById,
  savePostcommentData,
  usercreateSocialPost,
  savePostLikeData,
  getPostByWallId,
  fetchSocialByUserId,
  likeaudio,
  getAudioByFolderId,
  saveAudiocomment,
  getAudiocomments,
  getAudioBySubAdminId,
  PopularSocialListOnCourseBase,
  favoriteaudio,
  getLatestAudio,
  getSocialOfPurcahseCourse,
  startPurchaseEvent,
  getAudiofolders,
  getSubFolderList,
  getVideoByFolderId,
  GetSalesPageCoursesListSocialWall,
  favoritevideo,
  likevideo,
  getfolders,
  changeFreeSubsModelStatus,
  getcomments,
  savecomment,
  getVideoBySubadminId,
  getLatestVideo,
  updateUser,
  getFolders,
  getSubFolder,
  createSocialWall,
  createMemberEventPayment,
  SocialList,
  getUser,
  getAdminSubscriptions,
  getAllUsers,
  getPostList,
  createSocialPost,
  register,
  geteEnrolledWallMemById,
  login,
  subadminregister,
  memberlogin,
  forgotPwd,
  verify,
  createPassword,
  getAllCourses,
  enrollCourse,
  unenrollCourse,
  getEnrolledCourses,
  getCourseModule,
  getModule,
  getLiveLessonsModules,
  enrollChallenge,
  joinEvent,
  getMembershipLevel,
  createMemberCoursePayment,
  createMemberPayment,
  startSubscription,
  verifyAndCompleteSubscription,
  getAllInvoices,
  getSelectedEvent,
  GetSalesPage,
  addSingleMember,
  getNextOrPrevLessonDirect,
  getSaleSelectedCourse,
  socialWallUsers,
  courseLessonCount,
  getMemberData,
  courseSnapshot,
  unsubscribeEmailMember,
  GetSalesPageEventsListForMember,
  getSaleSelectedEvent,
  deleteChLessonCommentByUserId,
  editChallengeLessonCommentByUserId,
  deleteCourseLessonCommentByUserId,
  editEventCommentByUserId,
  deleteEventCommentByUserId,
  deletePostCommentByUserId,
  editPostCommentByUserId,
  subadminAllDataCount
};
