import React, { Component } from 'react';
import Header from '../common/Header';
import { Modal } from "react-bootstrap";
import cc from '../../images/enduser/credit-card.svg';
import cross from '../../images/enduser/cross.svg';
import audio from '../../images/audio/audio.png';
import m475 from '../../images/audio/Mask Group 475.png';
import heart from '../../images/audio/heart (4).svg';
import visibility from '../../images/audio/visibility (2).svg';
import Exclusion52 from '../../images/audio/Exclusion 52.svg';

import Group69405 from '../../images/audio/Group 69405.svg';

import placeholder from '../../images/placeholder.jpg';
import chat1 from '../../images/admin/chat (9).svg';
import heart1 from '../../images/admin/heart.svg';
import submit from '../../images/admin/submit.svg';

import ReactPlayer from 'react-player'
import close from '../../images/dashboard/modal-close.svg';
import $ from 'jquery';
import {
  getContentBySubs, getContentByFolderId, getLatestContent, getSubFolderList,
  getVideoByFolderId, getLatestVideo, getVideoBySubadminId, savecomment, getcomments,
  getContentfolders, likevideo, favoritevideo, subadminAllDataCount
} from '../../helper/service';
import magnifiyingGlass from '../../images/admin/magnifiying-glass.svg';
import searchDark from '../../images/enduser/search-dark.svg';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import folder from '../../images/admin/folder-sm.svg';
import file from '../../images/admin/file.svg';
import Loader from '../Loader';
import { monthNames } from '../../helper/constants';

const initialState = {
  isOpen: false,
  addPlayList: false,
  newVideo: '',
  playvideo: '',
  videoId: '',
  allFolder: '',
  videoTit: '',
  videocomment: '',
  is_loader: false,
  liked: heart,
  allFavVideo: '',
  alllatestVideo: '',
  isOpen2: false,
  subfoldersdatalist: '',
  description: '',
  isSub: false,
  objs: '',
  selectedParentFolder: '',
  newContent: '',
  listcontent: '',
  accessAllowed: false,
}

export default class Contentlibrary extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  openAddPlayList = () => this.setState({ addPlayList: true });
  closeAddPlayList = () => this.setState({ addPlayList: false });

  openModal1 = () => this.setState({ isOpen1: true });
  closeModal1 = () => this.setState({ isOpen1: false });

  openModal2 = () => this.setState({ isOpen2: true });
  closeModal2 = () => this.setState({ isOpen2: false });

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  searchData = (event) => {
    event.preventDefault();
    console.log(this.state.allFolder, "allcontentfolder")
    const { name, value } = event.target;
    let filteredNames = this.state.allFolder.filter(ele => ele.props.children[2].props.children.toLowerCase().includes(value.toLowerCase()));
    this.setState({ allFolder: filteredNames })
  }


  commentlist(id) {
    getcomments(id).then(data => {
      if (data.result) {
        this.iteratecomments(data.result.TotalComments[0])
        this.setState({ videoTit: data.result.TotalComments[0].title, description: data.result.TotalComments[0].description })
      }
    })
  }

  playvideo(url, id) {
    this.commentlist(id);
    this.setState({ playvideo: url, isOpen1: true, isOpen2: false, videoId: id })
  }


  async likeonvideo() {
    let subadminId = localStorage.getItem('subadminId');
    let user_id = localStorage.getItem('user_id');

    const commentData = {
      "videoId": this.state.videoId,
      "subAdminId": subadminId,
      "userId": user_id,
    }
    const likes = await likevideo(commentData);
    if (likes.data.result.message == "Likes Already Exist") {
      alert("You have already liked this video.")
    }
    this.setState({ liked: heart1 })
  }


  iteratecomments(datas) {
    let user_id = localStorage.getItem('user_id');
    if (datas.likedByUserId.length > 0) {
      // console.log(datas.likedByUserId);
      //var item = datas.likedByUserId.find(item => item.userId._id == user_id);

      let item = datas.likedByUserId.find(item => {
        if (item.userId) {
          if (item.userId._id == user_id) {
            return true;
          }
          return false;
        }
        else {
          return false;
        }
      }
      );



      // console.log(item)
      if (item) {
        if (item.userId) {
          this.setState({ liked: heart1 })
        } else {
          this.setState({ liked: heart })
        }
      }

    } else {
      this.setState({ liked: heart })
    }
    const comment = datas.commentByUserId.map(function (vid) {
      var imgs = "";

      var key = "";
      if (vid.userId) {
        key = vid.userId;
      } else {
        key = vid.subadminId;
      }


      if (key) {

        if (key.image == "") {
          imgs = placeholder;
        } else {
          imgs = key.image;
        }

        return (
          <div className="media">
            <img src={imgs} className="user-img img-fluid mr-3" />
            <div className="media-body">
              <h5 className="mt-0 messanger-name">{key.firstName} {key.lastName}
                {/* <span className="float-right msg-time">12:04</span> */}
              </h5>
              <p className="messanger-text">{vid.comment}
                {/* <span className="float-right msg-count">12</span> */}
              </p>
              {/* <a href="#" className="link">reply</a> */}
            </div>
          </div>
        )
      }
    }, this)
    if (datas.commentByUserId.length > 0) {
      this.setState({ videocomment: comment })
    } else {
      this.setState({ videocomment: 'No records found.' })
    }

  }



  iteratevideos(datas) {
    if (datas.result.length > 0) {
      var videos1 = datas.result.map(function (vid) {
        var imgs = vid.coverImage;
        return (
          <div className="card" onClick={this.playvideo.bind(this, vid.video, vid._id)}>
            <img src={imgs} className="card-img-top" alt="" />
            <h5 className="title">{vid.title}</h5>
            <p className="text">{vid.description}</p>
          </div>
        )
      }, this)
    } else {
      videos1 = "No Record Found.";
    }

    this.setState({ newVideo: videos1 })
  }



  iterateFolderContent(datas) {
    console.log(datas);
    if (datas.result.length > 0) {
      var videos1 = datas.result.map(function (vid) {
        if (vid.propertise.length > 0) {
          var imgs = vid.coverImage;
          var url = "/view-content?id=" + vid._id;
          return (
            <a href={url} target="_blank">
              <div className="card">
                <img src={vid.propertise[0]['cover_image']} className="card-img-top" alt="" />
                <h5 className="title">{vid.propertise[0]['title']}</h5>
              </div>
            </a>
          )
        }
      }, this)
    } else {
      videos1 = "No Record Found.";
    }

    this.setState({ newVideo: videos1 })
  }




  deleteVideo() {

  }

  iterateContent(datas, statekey) {
    if (datas.result.length > 0) {
      var contents1 = datas.result.map(function (vid) {

        if (vid.propertise.length > 0) {
          var url = "/view-content?id=" + vid._id;


          var currentDate = new Date();
          var scheduleDate = new Date(vid.propertise[0]['schedule']);

          console.log("vid.schedule======", vid.schedule);

          var today = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate()));
          var schDate = new Date(Date.UTC(scheduleDate.getUTCFullYear(), scheduleDate.getUTCMonth(), scheduleDate.getUTCDate()));

          var textData = "";
          if (scheduleDate.getUTCFullYear() == currentDate.getUTCFullYear() && scheduleDate.getUTCMonth() == currentDate.getUTCMonth() && scheduleDate.getUTCDate() == currentDate.getUTCDate()) {
            textData = "Live";
          } else {
            var months = parseInt(scheduleDate.getUTCMonth());
            textData = scheduleDate.getUTCDate() + '-' + monthNames[months] + '-' + scheduleDate.getUTCFullYear();
          }

          return (
            <tr>
              <td>
                <a href={url} target="_blank">
                  <img src={vid.propertise[0]['cover_image']} width={54} height={54} className="rounded-circle" />
                </a>
              </td>
              <td>
                <h6 className="title">{vid.propertise[0]['title']}</h6>
              </td>
              <td>
                <h6 className="title">{textData}</h6>
              </td>
            </tr>
          )
        }
      }, this)
    } else {
      contents1 = "";
    }



    if (statekey == "recent") {
      if (contents1.length > 0) {
        this.setState({ recentContent: contents1 })
      } else {
        this.setState({ recentContent: 'No Records Found.' })
      }
    }
    if (statekey == "scheduled") {
      if (contents1.length > 0) {
        this.setState({ scheduledContent: contents1 })
      } else {
        this.setState({ scheduledContent: 'No Records Found.' })
      }
    }
    if (statekey == "popular") {
      if (contents1.length > 0) {
        this.setState({ popular: contents1 })
      } else {
        this.setState({ popular: 'No Records Found.' })
      }
    }
  }


  iterateFolders(datas) {
    if (datas.result.length > 0) {
      var folders = datas.result.map(function (vid) {
        var imgs = vid.coverImage;
        return (
          <div className="card" onClick={this.selectMainFoldersList.bind(this, vid._id, vid)}>
            <img src={vid.image} className="card-img-top" alt="popular-image" />
            <h5 className="title">{vid.title}</h5>
          </div>
        )
      }, this)
    } else {
      folders = "No Record Found.";
    }

    this.setState({ allFolder: folders })
  }




  getVideosByFolderIds(idd) {

    $(document).ready(function () {
      $('.card').removeClass('active');
      $('#' + idd).addClass('active');
    })


    this.setState({ is_loader: true, selectedFolder: idd })
    getVideoByFolderId(idd).then(data => {
      this.iterateInnervideos(data);
      this.setState({ is_loader: false })
    })
  }




  iterateInnervideos(datas) {
    const videos2 = datas.result.map(function (vid) {
      console.log(vid);
      return (
        <div className="uploaded-file row align-items-center">
          <div className="col-2" onClick={this.playvideo.bind(this, vid.video, vid._id)}>
            <img src={Exclusion52} className="img-fluid" width={67} height={67} />
          </div>
          <div className="col-10">
            <h5 className="file-title">{vid.title} - {vid.description} </h5>
            {/* <p className="file-text">03:44 - Video Trance</p>                 */}
          </div>
        </div>
      )
    }, this)
    this.setState({ listvideo: videos2 })
  }


  selChildFolder(obj) {
    $(document).ready(function () {
      $('.card').removeClass('active');
      $('#' + obj._id).addClass('active');
    })

    //this.getVideosByFolderIds(obj._id);
    this.getContentByFolderIds(obj._id);

    localStorage.setItem('selectedFolder', obj._id);
    localStorage.setItem('selectedFolderTitle', obj.title);

    this.setState({
      subParentFolderImage: obj.image,
      subParentTitle: obj.title,
      subParentSubtitle: obj.subtitle,
      subparentTotalsubfolder: 0,
    })
  }


  viewcontent(id) {
    console.log(id);
    this.setState({ isOpen9: true })
  }


  iterateInnercontent(datas) {
    var contents1 = "";
    if (datas.result.content.length > 0) {
      contents1 = datas.result.content.map(function (vid) {
        if (vid.propertise.length > 0) {
          var url = "/view-content?id=" + vid._id;
          return (
            <a href={url} target="_blank">
              <div className="uploaded-file row align-items-center">
                <div className="col-2" onClick={this.viewcontent.bind(this, vid._id)}>
                  <img src={vid.propertise[0]['cover_image']} className="img-fluid" width={67} height={67} />
                </div>
                <div className="col-10">
                  <h5 className="file-title">{vid.propertise[0]['title']} - {vid.propertise[0]['short_description']} </h5>
                </div>
              </div>
            </a>
          )
        }
      }, this)
    }

    this.setState({ listcontent: contents1 })
  }



  getContentByFolderIds(idd) {

    $(document).ready(function () {
      $('.card').removeClass('active');
      $('#' + idd).addClass('active');
    })

    this.setState({ is_loader: true, selectedFolder: idd })
    getContentByFolderId(idd).then(data => {
      this.iterateInnercontent(data);
      this.setState({ is_loader: false })
    })
  }



  async selectMainFoldersList(idd, obj) {

    //localStorage.setItem('selectedFolder',idd);  
    //localStorage.setItem('selectedParentFolder',idd);  

    if (obj.totalsubfolder > 1) {
      var txt = "Sub folders";
    } else {
      var txt = "Sub folder";
    }

    this.setState({
      selectedFolder: idd,
      parentTitle: obj.title,
      parentSubtitle: obj.subtitle,
      parentTotalcontent: obj.totalcontent,
      parentTotalsubfolder: obj.totalsubfolder,
      parentFolderImage: obj.image,
      is_loader: true,
      selectedParentFolder: idd,
      objs: obj,
      subfolderText: txt
    })

    //   getVideoByFolderId(idd).then(data => {   
    //     this.iterateInnervideos(data);
    //  })
    //this.getVideosByFolderIds(idd);
    this.getContentByFolderIds(idd);


    const datas = await getSubFolderList(idd, 'contents');
    console.log(datas);
    var isSub = false;
    if (datas.result.length > 0) {
      var subfoldersdata = datas.result.map(function (subs) {
        if (subs.totalcontent > 1) {
          var txt = "Contents";
        } else {
          var txt = "Content";
        }
        return (
          <div className="card" id={subs._id} onClick={this.selChildFolder.bind(this, subs)}>
            <div className="card-body">
              <div className="row no-gutters">
                <div className="col-2">
                  <img src={subs.image} className="img-fluid mr-2" alt="book" height="75" width="75" />
                </div>
                <div className="col-10 pl-2 pl-md-4">
                  <h5 className="card-title">{subs.title}</h5>
                  <p className="card-text">{subs.subtitle}</p>
                  <div className="row">
                    <div className="col">
                      <div className="page-info">
                        <img src={file} />
                        {subs.totalcontent} {txt}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }, this)
    } else {
      isSub = true;
    }
    this.setState({
      subfoldersdatalist: subfoldersdata,
      is_loader: false,
      isSub: isSub
    })
    this.openModal2();
  }


  iterateFav(datas, types) {
    if (datas.result.length > 0) {
      var favVideo = datas.result.map(function (vid) {
        var imgs = heart;

        let user_id = localStorage.getItem('user_id');
        if (vid.likedByUserId.length > 0) {
          var item = vid.likedByUserId.find(item => item.userId == user_id);
          if (item.userId) {
            imgs = Group69405;
          }
        }

        return (
          <tr>
            <td>
              <div className="video-img">
                <img src={vid.coverImage} width={99} height={65} />
                {/* <img src={Exclusion58} width={35} height={35} className="play-btn" /> */}
              </div>
            </td>
            <td>
              <h3 className="title">{vid.title}</h3>
              <p className="text">{vid.description}</p>
            </td>
            <td>
              <img src={imgs} className="mx-auto d-block" />
            </td>
            <td>
              <div className="d-flex align-items-center justify-content-center">
                <img src={visibility} />
                <p className="views">{vid.likedByUserId.length} Likes | {vid.commentByUserId.length} Comments</p>
              </div>
            </td>
            <td onClick={this.playvideo.bind(this, vid.video, vid._id)}>
              <img src={Exclusion52} />
            </td>
            {/* <td>
                    <div className="dropdown dropleft">
                      <img src={Group69205} role="button" id data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Remove from Playlist</a>
                        <a className="dropdown-item" href="#">Go to folder</a>
                        <a className="dropdown-item" href="#">Add to playlist</a>
                      </div>
                    </div>
                  </td> */}
          </tr>
        )
      }, this)
    } else {
      favVideo = "No Record Found.";
    }

    if (types == "latest") {
      this.setState({ alllatestVideo: favVideo })
    } else {
      this.setState({ allFavVideo: favVideo })
    }
  }

  getLatestVideoList() {
    let subadminId = localStorage.getItem('subadminId');
    getLatestVideo(subadminId).then(data => {
      this.iterateFav(data, 'latest');
    })

    getVideoBySubadminId(subadminId).then(data => {
      this.iteratevideos(data);
    })

    getLatestContent(subadminId).then(data => {
      this.iterateContent(data);
    })

  }

  getAllFolderList() {
    let subadminId = localStorage.getItem('subadminId');
    getContentfolders(subadminId).then(data => {
      this.iterateFolders(data);
    })
  }


  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.savevideocomment();
    }
  }



  async savevideocomment() {
    if (this.state.comment) {
      this.setState({ is_loader: true })
      let subadminId = localStorage.getItem('subadminId');
      let user_id = localStorage.getItem('user_id');

      const commentData = {
        "videoId": this.state.videoId,
        "subAdminId": subadminId,
        "userId": user_id,
        "comment": this.state.comment
      }
      await savecomment(commentData);
      this.commentlist(this.state.videoId);
      this.setState({ is_loader: false, comment: '' })
    }
  }



  favoritevideoList() {
    let user_id = localStorage.getItem('user_id');
    favoritevideo(user_id).then(data => {
      this.iterateFav(data, 'fav');
    })
  }

  getRecentRecentList() {
    let uid = localStorage.getItem('user_id');
    let subadminId = localStorage.getItem('subadminId');
    //let uid = "61f3842a522ebb94739d9f3c";
    getContentBySubs(subadminId).then(data => {
      this.iterateContent(data, 'recent');
      this.iterateFolderContent(data);
      this.setState({ recentData: data.result })
    })
  }

  async componentDidMount() {

    subadminAllDataCount().then(data => {
      if (data?.result?.partnerData) {
        if (data.result.partnerData.foldersLength > data.result.partnerData.foldersAllowed) {
          this.setState({ accessAllowed: false });
        }
      }
    })

    this.getRecentRecentList();
    this.getAllFolderList()
    this.favoritevideoList();
    const subsId = localStorage.getItem('subscriptionId');
    console.log("subscriptionId = " + subsId);
  }


  render() {

    $(document).ready(function () {
      $('.videoPreviewModal').attr('id', 'videoPreviewModal');
      $('.insideFolderModal').attr('id', 'insideFolderModal');
    })


    return (
      <div className="wrapper">
        {this.state.is_loader ? (<Loader />) : (<></>)}
        <Header />

        <Modal show={this.state.isOpen} onHide={this.closeModal} id="setupGuide" backdrop="static" size="lg">
          <Modal.Body className="text-center">
            <div className="modal-header border-0 pb-0">
              <button type="button" className="close float-right" onClick={this.closeModal}>
                <span aria-hidden="true">
                  <img src={cross} />
                </span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <form className="text-center">
                <img src={cc} className="img-fluid mx-auto d-block" alt="payment-successful" />
                <h3 className="title">Payment done Successfully</h3>
                <p className="text">It is a long established fact that a reader will be <br />distracted by the readable content of a page when<br /> looking at its layout.</p>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.addPlayList} onHide={this.closeAddPlayList} id="addPlaylistModal" backdrop="static">
          <Modal.Body className="text-center">
            <div className="modal-header border-0 pb-0">
              <h4 className="modal-title">Add to Playlist</h4>
              <button type="button" className="close float-right" onClick={this.closeAddPlayList}>
                <span aria-hidden="true">
                  <img src={cross} />
                </span>
              </button>
            </div>

            <div className="modal-body pt-0">
              <form>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-custom">Create</button>
                </div>
                <h4 className="modal-title mb-4">Your Playlists</h4>
                <div className="d-flex justify-content-around align-items-center your-playlist">
                  <img src={m475} className="playlist-img" />
                  <p className="title">Intro's I like</p>
                  <span className="add"><i className="fas fa-plus" /></span>
                </div>
                <div className="d-flex justify-content-around align-items-center your-playlist">
                  <img src={audio} className="playlist-img" />
                  <p className="title">Morning walk</p>
                  <span className="add"><i className="fas fa-plus" /></span>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal className="videoPreviewModal" show={this.state.isOpen1} onHide={this.closeModal1} backdrop="static">
          <Modal.Header className="modal-header border-0 align-items-center pb-2">
            {/* <h5 class="modal-title">Preview from</h5> */}
            <button onClick={this.closeModal1} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><img src={close} /></span>
            </button>
          </Modal.Header>
          {/* <div className="modal-body">
        <div class="modal-body text-center">  
        <ReactPlayer url={this.state.playvideo} controls={true} playing={true} width="400"/> 
      </div>
      </div>   */}
          <div className="modal-body pt-0">
            <h5 className="video-title">Preview</h5>
            {/* <img src="images/admin/yoga1.png" className="img-fluid mx-auto d-block" width={423} height={353} /> */}
            <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }} url={this.state.playvideo} controls={true} playing={true} width="423" height="353" />
            <div className="chat-like d-flex justify-content-between align-items-center">
              <img src={chat1} className="img-fluid" width={42} height={42} />
              <p className="file-name">{this.state.videoTit}</p>
              <img src={this.state.liked} className="img-fluid" width={42} height={42} onClick={this.likeonvideo.bind(this)} />
            </div>
            <div className='player-description'><p>{this.state.description}</p></div>
            {/* <div className="cursor">
          <img src="images/admin/Ellipse 2922.svg" className="cursor-img img-fluid" width={23} height={23} />
        </div>
        <div className="timing d-flex align-items-center justify-content-between">
          <span>44:30</span>
          <span>57:00</span>
        </div> */}
            {/* <div className="controls d-flex align-items-center justify-content-between">
          <img src="images/admin/repeat.svg" className="img-fluid" width={33} height={36} />
          <img src="images/admin/previous.svg" className="img-fluid" width={33} height={31} />
          <img src="images/admin/play-lg.svg" className="img-fluid" width={96} height={102} />
          <img src="images/admin/next.svg" className="img-fluid" width={33} height={31} />
          <img src="images/admin/repeat.svg" className="img-fluid" width={33} height={36} />
        </div> */}
            <div className="comments">
              <div className="form-row mb-3">
                <div className="col-10">
                  <input type="text" className="form-control leave-comment" placeholder="Leave a comment" name="comment" onChange={this.onChange} onKeyPress={this.enterPressed.bind(this)} value={this.state.comment} />
                </div>
                <div className="col-2">
                  <img src={submit} className="img-fluid" width={56} height={38} onClick={this.savevideocomment.bind(this)} />
                </div>
              </div>
              <h4 className="title">Comments
                {/* <div className="custom-control custom-switch float-right">
              <input type="checkbox" className="custom-control-input" id="commentSwitch" defaultChecked />
              <label className="custom-control-label" htmlFor="commentSwitch" />
            </div> */}
              </h4>
              <div className="messages-list">
                {this.state.videocomment}
              </div>
            </div>
          </div>
        </Modal>

        <Modal className="insideFolderModal" show={this.state.isOpen2} onHide={this.closeModal2} backdrop="static">
          <Modal.Header className="align-items-center border-0 pb-0">
            {/* <div className="go-back" onClick={this.closeModal2}>
            <img src={goback} className="img-fluid mr-3" /> Folders List
          </div> */}
            <div className='go-back'>Folders List</div>
            <button onClick={this.closeModal2} type="button" className="close ml-5" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><img src={close} /></span>
            </button>
          </Modal.Header>

          <div className="folder">
            <div className="card" id={this.state.selectedParentFolder} onClick={this.selChildFolder.bind(this, this.state.objs)}>
              <div className="card-body">
                <div className="row no-gutters">
                  <div className="col-2">
                    <img src={this.state.parentFolderImage} className="img-fluid mr-2" alt="book" height="75" width="75" />
                  </div>
                  <div className="col-10 pl-2 pl-md-4">
                    <h5 className="card-title">{this.state.parentTitle}</h5>
                    <p className="card-text">{this.state.parentSubtitle}</p>
                    <div className="row">
                      <div className="col">
                        <div className="page-info">
                          <img src={folder} />
                          {this.state.parentTotalsubfolder} {this.state.subfolderText}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {(!this.state.isSub) ? <h3 className="title">Select Sub Folder</h3> : ''}
            <div className="scroll">
              {this.state.subfoldersdatalist}
            </div>
            <div className="scroll">
              {/* {this.state.listvideo} */}
              {this.state.listcontent}
            </div>
          </div>
        </Modal>
        {this.state.accessAllowed ?
          <div className="content-wrapper video-library admin-courses">
            <section className="">
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-8">
                      <h2 className="title">Content Library
                        {/* <a href="#">Return to Multimedia</a> */}
                      </h2>
                    </div>
                    <div className="col-lg-4">
                      <div className="search-course">
                        <input type="text" className="form-control" placeholder="Search Content Library" name="search" onChange={this.searchData} />
                        <span className="search-icon"><img src={searchDark} /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="popular-folder">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-center border-line">
                      <h2 className="title">Folders</h2>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-left flex-wrap scroll">
                  {this.state.allFolder}
                </div>
              </div>
            </section>


            {/* 
          <section className="new-video">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center border-line">
                    <h2 className="title">New Content</h2>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-left flex-wrap scroll">
                {this.state.newVideo}
              </div>
            </div>
          </section> */}

            <section className="videos">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-center border-line">
                      <h2 className="title">Content</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs" id="videoTab" role="tablist">
                      {/* <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="youFav-tab" data-toggle="tab" href="#youFav" role="tab" aria-controls="youFav" aria-selected="true">Your Favourites</a>
                  </li> */}
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="latestCont-tab" data-toggle="tab" href="#latestCont" role="tab" aria-controls="latestCont" aria-selected="false">Latest Content</a>
                      </li>
                      <li className="nav-item ml-auto" role="presentation">
                        <a className="nav-link" id="viewAll-tab" data-toggle="tab" href="#" role="tab">View all <i className="fas fa-angle-right" /></a>
                      </li>
                    </ul>
                    <div className="tab-content scroll" id="videoTabContent">
                      <div className="tab-pane fade show active" id="youFav" role="tabpanel" aria-labelledby="youFav-tab">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <tbody>
                              {this.state.recentContent}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="latestCont" role="tabpanel" aria-labelledby="latestCont-tab">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            <tbody>
                              {this.state.recentContent}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          :
          <div className="content-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="access-denied">
                    <h2>Folders are not available</h2>
                    <p>Sorry, you do not have access to this page. Please contact your instructor.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

      </div>
    )
  }
}
